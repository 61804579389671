import { Html } from '@react-three/drei'
import React, { useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { useThree } from '@react-three/fiber'
const round = (no) => no.toFixed(2)
export const DebugValues = (props) => {
    const [collapse,set] = useState(true)
    const {camera} = useThree()
    return (
        <Html
            portal={props.parentDiv.current}
            className={css(styles.container)}
        >
            <p>Debugger</p>
            <>
                {!collapse && <div>
                    <p>{`Camera positions:[${Object.values(camera.position).map(round).map(String).join(',')}]`}</p>
                    {/* <p>{`Camera target:[${Object.values(camera.position).map(String).join('')}]`}</p> */}
                    <p>{`Camera fov:${camera.fov}`}</p>
                </div>}
            </>
            <button onClick={()=>set(state=>!state)}>Show</button>
        </Html>
    )
}

const styles = StyleSheet.create({
    container:{
        position:'absolute',
        top:'-10vh',
        left:'-28vw',
        width:'20vw',
        backgroundColor:'rgba(255,255,255,0.7)'
    }
})