/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 Kitchen_Environment.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function KitchenModel(props) {
  const group = useRef()
  const { nodes, materials, animations } = props.scene
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Environment">
          <primitive object={nodes.Static} />
          <primitive object={nodes['5525_Drawer']} />
          <primitive object={nodes['5525_Flip1']} />
          <primitive object={nodes['5525_Flip2']} />
          <primitive object={nodes['5526_C1']} />
          <primitive object={nodes['5526_C2']} />
          <primitive object={nodes['5526_C3']} />
          <primitive object={nodes['5526_C4']} />
          <primitive object={nodes['5526_C1_Slide']} />
          <primitive object={nodes['5526_C2_Slide']} />
          <primitive object={nodes['5526_C3_Slide']} />
          <primitive object={nodes['5526_C4_Slide']} />
          <primitive object={nodes['4268_Drawer']} />
          <primitive object={nodes['3151_Cup_Drawer']} />
          <primitive object={nodes.Wok_Drawer} />
          <primitive object={nodes['3153_Drawer']} />
          <primitive object={nodes['3148_Pan']} />
          <primitive object={nodes['4268_Drawer_Slide']} />
          <primitive object={nodes['3151_Cup_Slide']} />
          <primitive object={nodes.Wok_Slide} />
          <primitive object={nodes['3153_Drawer_Slide']} />
          <primitive object={nodes['3148_Pan_Slide']} />
          <primitive object={nodes['5529_Drawer1']} />
          <primitive object={nodes['5529_Drawer2']} />
          <primitive object={nodes['5529_Drawer1_Slide']} />
          <primitive object={nodes['5529_Drawer2_Slide']} />
          <primitive object={nodes.SCS_Door_Left} />
          <primitive object={nodes.SCS_Door_Right} />
          <primitive object={nodes.SCS_Left_SlideOut} />
          <primitive object={nodes.SCS_Right_SlideOut} />
          <primitive object={nodes.SCS_Middle_SlideOut_Top} />
          <primitive object={nodes.SCS_Middle_SlideOut_Bottom} />
          <primitive object={nodes['5521_Drawer']} />
          <primitive object={nodes['5521_SideSlider']} />
          <primitive object={nodes['4330_Door']} />
          <primitive object={nodes['4330_Door_Unit']} />
          <primitive object={nodes['4330_Inside_Unit']} />
          <primitive object={nodes.neutral_bone} />
          <mesh name="Cube010" geometry={nodes.Cube010.geometry} material={materials['4330']} position={[-1.288, 1.004, -1.282]} />
          <mesh name="Cylinder008" geometry={nodes.Cylinder008.geometry} material={materials['4330']} position={[-1.069, 0, -1.07]} />
          <mesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials['4330']} position={[-1.069, 0, -1.07]} />
          <skinnedMesh name="10_5521_SSP_assy_with_carcass005" geometry={nodes['10_5521_SSP_assy_with_carcass005'].geometry} material={materials['5521']} skeleton={nodes['10_5521_SSP_assy_with_carcass005'].skeleton} />
          <skinnedMesh name="10_5521_SSP_assy_with_carcass009" geometry={nodes['10_5521_SSP_assy_with_carcass009'].geometry} material={materials['5521']} skeleton={nodes['10_5521_SSP_assy_with_carcass009'].skeleton} />
          <skinnedMesh name="10_5521_SSP_assy_with_carcass010" geometry={nodes['10_5521_SSP_assy_with_carcass010'].geometry} material={materials['5521']} skeleton={nodes['10_5521_SSP_assy_with_carcass010'].skeleton} />
          <skinnedMesh name="11-17_SKIDO_Adaptors_Carcass_Assy001" geometry={nodes['11-17_SKIDO_Adaptors_Carcass_Assy001'].geometry} material={materials['Slim Ergo']} skeleton={nodes['11-17_SKIDO_Adaptors_Carcass_Assy001'].skeleton} />
          <skinnedMesh name="11-17_SKIDO_Adaptors_Carcass_Assy002" geometry={nodes['11-17_SKIDO_Adaptors_Carcass_Assy002'].geometry} material={materials.Env_3} skeleton={nodes['11-17_SKIDO_Adaptors_Carcass_Assy002'].skeleton} />
          <skinnedMesh name="11-17_SKIDO_Adaptors_Carcass_Assy008" geometry={nodes['11-17_SKIDO_Adaptors_Carcass_Assy008'].geometry} material={materials.Env_2} skeleton={nodes['11-17_SKIDO_Adaptors_Carcass_Assy008'].skeleton} />
          <skinnedMesh name="Bin1_1001" geometry={nodes.Bin1_1001.geometry} material={materials.Env_2} skeleton={nodes.Bin1_1001.skeleton} />
          <group name="Bin1_1009">
            <skinnedMesh name="Cube057" geometry={nodes.Cube057.geometry} material={materials['5529_Plastic']} skeleton={nodes.Cube057.skeleton} />
            <skinnedMesh name="Cube057_1" geometry={nodes.Cube057_1.geometry} material={materials['5529_Grains_And_Metallic']} skeleton={nodes.Cube057_1.skeleton} />
          </group>
          <skinnedMesh name="Bin1_2001" geometry={nodes.Bin1_2001.geometry} material={materials['5529_Plastic']} skeleton={nodes.Bin1_2001.skeleton} />
          <skinnedMesh name="Bin1_2003" geometry={nodes.Bin1_2003.geometry} material={materials['5529_Plastic']} skeleton={nodes.Bin1_2003.skeleton} />
          <skinnedMesh name="Bin1_2005" geometry={nodes.Bin1_2005.geometry} material={materials['5529_Plastic']} skeleton={nodes.Bin1_2005.skeleton} />
          <skinnedMesh name="Bin1_2006" geometry={nodes.Bin1_2006.geometry} material={materials['5529_Plastic']} skeleton={nodes.Bin1_2006.skeleton} />
          <skinnedMesh name="Circle" geometry={nodes.Circle.geometry} material={materials['3148']} skeleton={nodes.Circle.skeleton} />
          <skinnedMesh name="Cube" geometry={nodes.Cube.geometry} material={materials['3153']} skeleton={nodes.Cube.skeleton} />
          <group name="Cube002">
            <skinnedMesh name="Cube004_1" geometry={nodes.Cube004_1.geometry} material={materials['5535.001']} skeleton={nodes.Cube004_1.skeleton} />
            <skinnedMesh name="Cube004_2" geometry={nodes.Cube004_2.geometry} material={materials['5535']} skeleton={nodes.Cube004_2.skeleton} />
          </group>
          <skinnedMesh name="Cube003" geometry={nodes.Cube003.geometry} material={materials.Env_2} skeleton={nodes.Cube003.skeleton} />
          <skinnedMesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials.Env_3} skeleton={nodes.Cube004.skeleton} />
          <skinnedMesh name="Cube005" geometry={nodes.Cube005.geometry} material={materials.SCS} skeleton={nodes.Cube005.skeleton} />
          <skinnedMesh name="Cube006" geometry={nodes.Cube006.geometry} material={materials.SCS} skeleton={nodes.Cube006.skeleton} />
          <skinnedMesh name="Cube007" geometry={nodes.Cube007.geometry} material={materials.Env_3} skeleton={nodes.Cube007.skeleton} />
          <skinnedMesh name="Cube008" geometry={nodes.Cube008.geometry} material={materials['4330']} skeleton={nodes.Cube008.skeleton} />
          <skinnedMesh name="Cube012" geometry={nodes.Cube012.geometry} material={materials.Env_2} skeleton={nodes.Cube012.skeleton} />
          <skinnedMesh name="Cube013" geometry={nodes.Cube013.geometry} material={materials.Env_2} skeleton={nodes.Cube013.skeleton} />
          <skinnedMesh name="Cube015" geometry={nodes.Cube015.geometry} material={materials['Slim Ergo']} skeleton={nodes.Cube015.skeleton} />
          <skinnedMesh name="Cube016" geometry={nodes.Cube016.geometry} material={materials['Slim Ergo']} skeleton={nodes.Cube016.skeleton} />
          <skinnedMesh name="Cube017" geometry={nodes.Cube017.geometry} material={materials.SCS} skeleton={nodes.Cube017.skeleton} />
          <skinnedMesh name="Cube018" geometry={nodes.Cube018.geometry} material={materials['Slim Ergo']} skeleton={nodes.Cube018.skeleton} />
          <skinnedMesh name="Cube019" geometry={nodes.Cube019.geometry} material={materials.Env_2} skeleton={nodes.Cube019.skeleton} />
          <skinnedMesh name="Cube020" geometry={nodes.Cube020.geometry} material={materials['Slim Ergo']} skeleton={nodes.Cube020.skeleton} />
          <skinnedMesh name="Cube021" geometry={nodes.Cube021.geometry} material={materials['Slim Ergo']} skeleton={nodes.Cube021.skeleton} />
          <skinnedMesh name="Cube022" geometry={nodes.Cube022.geometry} material={materials.Env_2} skeleton={nodes.Cube022.skeleton} />
          <skinnedMesh name="Cube023" geometry={nodes.Cube023.geometry} material={materials.Env_2} skeleton={nodes.Cube023.skeleton} />
          <skinnedMesh name="Cube024" geometry={nodes.Cube024.geometry} material={materials.SCS} skeleton={nodes.Cube024.skeleton} />
          <skinnedMesh name="Cube025" geometry={nodes.Cube025.geometry} material={materials.SCS} skeleton={nodes.Cube025.skeleton} />
          <skinnedMesh name="Cube026" geometry={nodes.Cube026.geometry} material={materials.SCS} skeleton={nodes.Cube026.skeleton} />
          <skinnedMesh name="Cube027" geometry={nodes.Cube027.geometry} material={materials.SCS} skeleton={nodes.Cube027.skeleton} />
          <skinnedMesh name="Cube029" geometry={nodes.Cube029.geometry} material={materials.Env_2} skeleton={nodes.Cube029.skeleton} />
          <skinnedMesh name="Cube030" geometry={nodes.Cube030.geometry} material={materials['5521']} skeleton={nodes.Cube030.skeleton} />
          <skinnedMesh name="Cube031" geometry={nodes.Cube031.geometry} material={materials['5521']} skeleton={nodes.Cube031.skeleton} />
          <skinnedMesh name="Cube032" geometry={nodes.Cube032.geometry} material={materials['5521']} skeleton={nodes.Cube032.skeleton} />
          <skinnedMesh name="Cube033" geometry={nodes.Cube033.geometry} material={materials['4330']} skeleton={nodes.Cube033.skeleton} />
          <skinnedMesh name="Cube036" geometry={nodes.Cube036.geometry} material={materials['5521']} skeleton={nodes.Cube036.skeleton} />
          <group name="Cube043">
            <skinnedMesh name="Cube018_1" geometry={nodes.Cube018_1.geometry} material={materials['4330']} skeleton={nodes.Cube018_1.skeleton} />
            <skinnedMesh name="Cube018_2" geometry={nodes.Cube018_2.geometry} material={materials.Env_2} skeleton={nodes.Cube018_2.skeleton} />
          </group>
          <skinnedMesh name="Cube044" geometry={nodes.Cube044.geometry} material={materials['4330']} skeleton={nodes.Cube044.skeleton} />
          <skinnedMesh name="Cube045" geometry={nodes.Cube045.geometry} material={materials.Env_2} skeleton={nodes.Cube045.skeleton} />
          <skinnedMesh name="Cube048" geometry={nodes.Cube048.geometry} material={materials.Env_1} skeleton={nodes.Cube048.skeleton} />
          <skinnedMesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} material={materials.Thali} skeleton={nodes.Cylinder006.skeleton} />
          <skinnedMesh name="geo_3153001" geometry={nodes.geo_3153001.geometry} material={materials['3160']} skeleton={nodes.geo_3153001.skeleton} />
          <skinnedMesh name="Org1" geometry={nodes.Org1.geometry} material={materials['4258']} skeleton={nodes.Org1.skeleton} />
          <skinnedMesh name="Plane" geometry={nodes.Plane.geometry} material={materials.Environment_Structure} skeleton={nodes.Plane.skeleton} />
          <skinnedMesh name="Plane002" geometry={nodes.Plane002.geometry} material={materials.Environment_Structure} skeleton={nodes.Plane002.skeleton} />
          <skinnedMesh name="Plane003" geometry={nodes.Plane003.geometry} material={materials.SCS} skeleton={nodes.Plane003.skeleton} />
          <skinnedMesh name="Plane004" geometry={nodes.Plane004.geometry} material={materials.Env_2} skeleton={nodes.Plane004.skeleton} />
          <skinnedMesh name="Plane005" geometry={nodes.Plane005.geometry} material={materials['5521']} skeleton={nodes.Plane005.skeleton} />
          <skinnedMesh name="Plane006" geometry={nodes.Plane006.geometry} material={materials['5521']} skeleton={nodes.Plane006.skeleton} />
          <skinnedMesh name="Plane009" geometry={nodes.Plane009.geometry} material={materials.SCS} skeleton={nodes.Plane009.skeleton} />
          <group name="Plane011">
            <skinnedMesh name="Plane002_1" geometry={nodes.Plane002_1.geometry} material={materials['5529_Grains_And_Metallic']} skeleton={nodes.Plane002_1.skeleton} />
            <skinnedMesh name="Plane002_2" geometry={nodes.Plane002_2.geometry} material={materials['5529_Plastic']} skeleton={nodes.Plane002_2.skeleton} />
          </group>
          <skinnedMesh name="Plane012" geometry={nodes.Plane012.geometry} material={materials['5529_Grains_And_Metallic']} skeleton={nodes.Plane012.skeleton} />
          <skinnedMesh name="Plane013" geometry={nodes.Plane013.geometry} material={materials['5529_Grains_And_Metallic']} skeleton={nodes.Plane013.skeleton} />
          <skinnedMesh name="Plane016" geometry={nodes.Plane016.geometry} material={materials['5529_Grains_And_Metallic']} skeleton={nodes.Plane016.skeleton} />
          <skinnedMesh name="Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023001" geometry={nodes['Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023001'].geometry} material={materials.SCS} skeleton={nodes['Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023001'].skeleton} />
          <skinnedMesh name="Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023002" geometry={nodes['Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023002'].geometry} material={materials.SCS} skeleton={nodes['Retopo_27-28-29_SCS_Movino_Flat_Base_Ref_Assy_27092023002'].skeleton} />
          <skinnedMesh name="Skido_wok_&_kadhai_organiser_017" geometry={nodes['Skido_wok_&_kadhai_organiser_017'].geometry} material={materials['Wok and Kadhai']} skeleton={nodes['Skido_wok_&_kadhai_organiser_017'].skeleton} />
          <group name="Undersink_Solution">
            <skinnedMesh name="Cube017_1" geometry={nodes.Cube017_1.geometry} material={materials.Metallic} skeleton={nodes.Cube017_1.skeleton} />
            <skinnedMesh name="Cube017_2" geometry={nodes.Cube017_2.geometry} material={materials.Plastic} skeleton={nodes.Cube017_2.skeleton} />
          </group>
          <skinnedMesh name="Undersink_Solution001" geometry={nodes.Undersink_Solution001.geometry} material={materials.Env_3} skeleton={nodes.Undersink_Solution001.skeleton} />
          <skinnedMesh name="Undersink_Solution002" geometry={nodes.Undersink_Solution002.geometry} material={materials.Env_2} skeleton={nodes.Undersink_Solution002.skeleton} />
          <skinnedMesh name="Universal_Skido_Adapter_T_&_CS_Assy001" geometry={nodes['Universal_Skido_Adapter_T_&_CS_Assy001'].geometry} material={materials.Thali} skeleton={nodes['Universal_Skido_Adapter_T_&_CS_Assy001'].skeleton} />
          <skinnedMesh name="Universal_Skido_Adapter_T_&_CS_Assy002" geometry={nodes['Universal_Skido_Adapter_T_&_CS_Assy002'].geometry} material={materials.lambert1} skeleton={nodes['Universal_Skido_Adapter_T_&_CS_Assy002'].skeleton} />
        </group>
        <mesh name="Cube047" geometry={nodes.Cube047.geometry} material={materials.Env_1} position={[-2.014, 1.024, -1.326]} />
        <mesh name="Cube037" geometry={nodes.Cube037.geometry} material={materials.Env_Props} position={[0.557, 1.515, 0.629]} rotation={[0, -1.571, 0]} />
        <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.Env_Props} position={[0.583, 1.618, 0.047]} />
        <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.Env_Props} position={[0.69, 1.641, 0.022]} />
        <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.Env_Props} position={[0.552, 1.685, -0.8]} scale={0.027} />
        <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.Env_Props} position={[0.627, 1.865, -0.583]} />
        <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.Env_Props} position={[0.617, 1.863, -0.821]} scale={0.889} />
        <mesh name="Circle009" geometry={nodes.Circle009.geometry} material={materials.Env_Props} position={[0.616, 1.9, -0.818]} />
        <mesh name="Cube039" geometry={nodes.Cube039.geometry} material={materials.Env_Props} position={[0.438, 1.865, -0.183]} />
        <mesh name="Cube040" geometry={nodes.Cube040.geometry} material={materials.Env_Props} position={[0.35, 0.806, -0.232]} />
        <mesh name="Circle015" geometry={nodes.Circle015.geometry} material={materials.Env_Props} position={[-0.73, 0.838, -1.565]} />
        <mesh name="Probka" geometry={nodes.Probka.geometry} material={materials.Env_Props} position={[-0.198, 1.096, 2.001]} scale={1.003} />
        <mesh name="Line001" geometry={nodes.Line001.geometry} material={materials.Env_Props} position={[-0.257, 0.831, 1.978]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.001} />
        <mesh name="Cylinder005" geometry={nodes.Cylinder005.geometry} material={materials.Env_Props} position={[-0.241, 0.875, 1.942]} />
        <mesh name="3DGeom~18_Defintion" geometry={nodes['3DGeom~18_Defintion'].geometry} material={materials.Env_Props} position={[0.645, 0.889, 0.035]} />
        <mesh name="3DGeom~18_Defintion001" geometry={nodes['3DGeom~18_Defintion001'].geometry} material={materials.Env_Props} position={[0.702, 0.898, 0.002]} />
        <mesh name="3DGeom~18_Defintion002" geometry={nodes['3DGeom~18_Defintion002'].geometry} material={materials.Env_Props} position={[0.696, 0.917, 0.074]} />
        <mesh name="Circle006" geometry={nodes.Circle006.geometry} material={materials.Env_Props} position={[-1.108, 2.615, 3.686]} />
        <mesh name="base" geometry={nodes.base.geometry} material={materials.Env_Props} position={[-0.43, 0.893, -1.482]} rotation={[Math.PI, -0.412, Math.PI]} scale={0.903} />
        <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} material={materials.Env_Props} position={[0.695, 0.885, -0.32]} scale={0.058} />
        <mesh name="Cylinder012" geometry={nodes.Cylinder012.geometry} material={materials.Env_Props} position={[0.695, 0.885, -0.446]} scale={0.058} />
        <mesh name="Cylinder014" geometry={nodes.Cylinder014.geometry} material={materials.Env_Props} position={[0.695, 0.885, -0.189]} scale={0.058} />
        <mesh name="DishRack_DishRack_0001" geometry={nodes.DishRack_DishRack_0001.geometry} material={materials.Env_Props} position={[-0.118, 1.02, -1.256]} rotation={[0, 1.571, 0]} scale={0.008} />
        <mesh name="Plane007" geometry={nodes.Plane007.geometry} material={materials.Env_Props} position={[0.465, 0.84, 0.609]} scale={0.274} />
        <mesh name="Cube035" geometry={nodes.Cube035.geometry} material={materials.Env_1} />
        <mesh name="Cube041" geometry={nodes.Cube041.geometry} material={materials.Env_1} />
        <mesh name="Cube034" geometry={nodes.Cube034.geometry} material={materials.Env_1} position={[-0.571, 1.674, -1.492]} rotation={[0, 1.571, 0]} />
        <mesh name="Cube050" geometry={nodes.Cube050.geometry} material={materials.Env_1} position={[-0.571, 1.674, -1.253]} rotation={[0, 1.571, 0]} />
        <mesh name="Cube062" geometry={nodes.Cube062.geometry} material={materials.Env_1} position={[0.611, 1.674, -1.492]} rotation={[0, 1.571, 0]} />
        <mesh name="Cube038" geometry={nodes.Cube038.geometry} material={materials.Env_1} position={[0.621, 1.729, -0.177]} />
        <mesh name="Cylinder015" geometry={nodes.Cylinder015.geometry} material={materials.Env_1} position={[-1.105, 0.448, 3.689]} scale={1.377} />
        <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials.Env_2} />
        <mesh name="Cube046" geometry={nodes.Cube046.geometry} material={materials.Env_2} position={[-2.014, 1.024, -1.016]} />
        <mesh name="Cube028" geometry={nodes.Cube028.geometry} material={materials.Env_3} position={[0.389, 0.485, 2.037]} />
        <mesh name="Super_Slim_Ergo001" geometry={nodes.Super_Slim_Ergo001.geometry} material={materials.Env_3} />
        <mesh name="Cube042" geometry={nodes.Cube042.geometry} material={materials.Env_3} position={[0.445, 0.963, -0.246]} />
        <mesh name="Cube009" geometry={nodes.Cube009.geometry} material={materials.Env_3} position={[-1.069, 0, -1.07]} />
        <mesh name="Cube063" geometry={nodes.Cube063.geometry} material={materials.Env_3} position={[0.247, 1.674, -1.253]} />
      </group>
    </group>
  )
}

useGLTF.preload('/Kitchen_Environment.gltf')
