import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { pageIdx } from '../../../constants'
import { animated as a } from '@react-spring/web'
import { usePage, useQR } from '../../../stores'
import { useState } from 'react'
import { useSpring } from '@react-spring/core'
// import { Intro } from './pages/Intro'
import { Catalog } from './pages/Catalog'
import { ProductViewer } from './pages/ProductsViewer'
import { Categories } from './pages/Categories'
import { QRModal } from '../../core/QRModal'
const check = (page) => {
    //console.log(page)
    return page!=='' && page!=='cylinder' && page!=='cylinderoutside' && page!=='showcase'
}
export const Fullscreen = (props) => {
    const pageState = usePage()
    const qr = useQR()
    const spring = useSpring({opacity:check(pageState.page)?1:0}) // check this
    return (
        // <a.div style={{...spring,pointerEvents:pageState.page==='' || pageState.page==='catalog'?'auto':'none'}} className={css(styles.container)}>
        <>
            <div className={css(styles.container)}>
                {/* <Intro /> */}
                <Catalog />
                <ProductViewer />
                <Categories />
            </div>    
            {qr.url && <QRModal />}
        </>
        
    )
}

const styles = StyleSheet.create({
    container:{

        position:"absolute",
        top:0,
        left:0,
        // backgroundColor: 'rgba(51, 51, 51, 0.95)',
        width:'100%',
        // filter:'blur(1px)',
        height:'100%',
        zInde:500
        
    }
})