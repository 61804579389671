import React, { useEffect, useState, useRef } from 'react'
import { css, StyleSheet } from 'aphrodite'
import {
  buttonStylesV2,
  colorThemes,
  textStyles,
  textStylesV2,
} from '../../../../appStyles'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { CircleLine } from '../../../assets/svgs/CircleLine'
import { openURLNewTab } from '../../../../helper'
import { Slide } from '../../fullscreen/pages/components/Slide'
import { useMeasure, useMouse, useWindowSize } from 'react-use'
import { FeatureSlide } from '../../fullscreen/pages/components/FeatureSlide'
import { SimilarSlide } from '../../fullscreen/pages/components/SimilarSlide'
import { NewCard } from '../../fullscreen/pages/components/NewCard'
import { thumbnailUrl } from '../../../../cms/cmsstore'
import {
  usePage,
  usePopup,
  useCylinder,
  getSimilarProducts,
  useProducts,
} from '../../../../stores'
import { useHistory } from 'react-router'
import { sendEvent } from '../../../../google-analytics'

const placeholderfeatures = `1. Contemporary design : Exquisitely crafted to suit a premium home decor.
  2. New mechanism : Prevents the handle from sagging and distributes the load across the mounting plate and not on allen screws.
  3. High-gradeelectroplating and lacquer enhances the door decor: The high-grade plating gives corrosion resistance of up to 96 hours and pencil hardness upto 4H+.
  4. Suitable for doors of thickness : 28 mm to 60 mm.
  5. Flexibility : The combination of a handle and rosette gives flexibility of using any europrofile mortise lock.`

const extractFeatures = (s) => {}
const FeatureBox = ({ feature, page, idx, model }) => {
  // const [ref,{x,left}] = useMeasure()
  const [x, setX] = useState(1)
  const ref = useRef()
  // const page = usePage()
  const [obj, setObj] = useState({})
  const popup = usePopup()
  const { width } = useWindowSize()
  const [dontGrey, setDontGrey] = useState(true)
  useEffect(() => {
    const splitWord = (s) => {
      for (let i = 0; i < s.length; i++) {
        // console.log('feature: ' + s.includes('high-grade'))
        if (s[i] == '-' || s[i] == ':') {
          setObj({
            heading: s.includes('high-grade')
              ? s.slice(0, i && s.indexOf(':'))
              : s.slice(0, i),
            text: s.includes('high-grade')
              ? s.slice(s.indexOf(':') + 2, s.length)
              : s.slice(i + 1, s.length),
          })
          // && s.indexOf(':')
          // s.indexOf(':') + 1
          // setObj({
          //   heading: s.subString(0, s.indexOf(':')),
          //   text: s.slice(i + 1, s.length),
          // })
          break
        }
      }
    }
    splitWord(feature)
  }, [feature])
  useEffect(() => {
    // console.log(x,left,width,width*0.7)
    // setDontGrey(true)
    // setTimeout(()=>setX(ref.current?.getBoundingClientRect().x),[200])
    // if(idx==2)console.log(ref.current?.getBoundingClientRect())
    // setX(ref.current?.getBoundingClientRect().x)
    // setTimeout(()=>{
    //   setDontGrey(false)
    //   if(idx==2)console.log(ref.current?.getBoundingClientRect(),width*0.82)
    //   setX(ref.current.getBoundingClientRect().x)
    // },[700])
  }, [width, page])

  const spring = useSpring({
    // backgroundColor:((x!=0 && x>width*0.82 && !dontGrey))?'grey':'white',
    // opacity:x>width*0.82?0:1
  })
  return (
    <a.div
      ref={ref}
      style={{
        color: 'white',
        fontSize: model ? '1.7vh' : '1.3vh',
        cursor: true ? 'grabbing' : 'grab',
        ...spring,
      }}
      className={css(styles.featureBox)}
    >
      <h5
        className={css(styles.featureH, textStylesV2.GodrejGreen)}
        style={{
          color: 'rgba(70, 193, 0, 1)',
          fontSize: model ? '1.9vh' : '1.6vh',
        }}
      >
        {obj.heading}
      </h5>
      <p style={{ color: 'white' }} className={css(styles.featuresP)}>
        {obj.text}
      </p>
      {/* <p  className={css(styles.featuresP)}>{feature.slice(2,feature.length)}</p> */}
    </a.div>
  )
}
export const Content = ({ model, ...props }) => {
  // console.log(props)
  const [page, setPage] = useState(0)
  const history = useHistory()
  const [ref, { width }] = useMeasure()
  const cylinder = useCylinder()
  const [features, setFeatures] = useState(placeholderfeatures.split('\n'))
  const popup = usePopup()
  const [data, setData] = useState({})
  const [similarProducts, setSimilar] = useState([])
  const productState = useProducts()

  useEffect(() => {
    if (!props.features) setFeatures(placeholderfeatures.split('\n'))
    else setFeatures(props.features.split('\n'))
  }, [props.features])
  useEffect(() => {
    // setFeatures(placeholderfeatures)
  }, [])
  const onClickViewAllProducts = () => {
    // console.log(props)
    sendEvent(
      `Prod ${props.productCode} View all clicked`,
      'Product Viewer',
      'View all products'
    )
    history.push(`/catalog?category=${Number(cylinder.room) - 1}`)
  }
  const handleClick = (prodCode) => {
    history.push(`/mv?id=${prodCode}`)
  }

  // useEffect(() => {
  //   console.log('similar products: ' + props.name)
  //   if (props.name) {
  //     setData(props)
  //     setSimilar(
  //       getSimilarProducts(props.tags, productState.products, props.productCode)
  //     )
  //   }
  // }, [props])
  // let features = placeholderfeatures
  return props.name ? (
    <div
      ref={ref}
      className={css(
        styles.container,
        model ? styles.modelContainer : styles.boxShadow
      )}
    >
      <h1 className={css(styles.heading, textStylesV2.HeadlineS)}>
        {props.category && (
          <div style={{ float: 'right' }}>
            <h1
              className={css(styles.collection)}
              style={{ fontSize: !model ? '2vh' : '2.5vh' }}
            >
              {props.category}
            </h1>
            <h1
              className={css(styles.productCode)}
              style={{
                margin: '-2.4vh 0 0 0',
                fontSize: !model ? '1.38vh' : '1.88vh',
              }}
            >
              Product Code: {props.productCode}
            </h1>
            {/* <div className={css(styles.hideoverflow)}></div> */}
          </div>
        )}
        {props.name}
      </h1>
      {!props.category && (
        <div style={{ float: 'right' }}>
          <h1
            className={css(styles.collection)}
            style={{ fontSize: !model ? '2vh' : '2.3vh' }}
          >
            {props.category}
          </h1>
          <h1
            className={css(styles.productCode)}
            style={{
              margin: '-1.2vh 0 0 0',
              fontSize: !model ? '1.38vh' : '1.88vh',
            }}
          >
            Product Code: {props.productCode}
          </h1>
          {/* <div className={css(styles.hideoverflow)}></div> */}
        </div>
      )}
      <h1
        className={css(styles.finish)}
        style={{ fontSize: model ? '2vh' : '1.78vh' }}
      >
        {props.finish}
      </h1>
      <p
        className={css(styles.smaller, textStylesV2.BodyS, styles.desc)}
        style={{
          fontSize: model ? '1vw' : '0.8vw',
          lineHeight: model ? '1.2vw' : '0.9vw',
        }}
      >
        {props.description}
      </p>
      <div className={css(styles.featuresFlex)}>
        <p
          className={css(styles.features)}
          style={{ fontSize: model ? '2.5vh' : '2.2vh' }}
        >
          Features
        </p>
        {/* <p>icon</p> */}
      </div>
      <FeatureSlide page={page} setPage={setPage} slideWidth={width} noArrow>
        {features.map((feature, i) => {
          return feature ? (
            <FeatureBox
              idx={i}
              key={i}
              page={page}
              feature={feature}
              model={model}
            />
          ) : null
        })}
      </FeatureSlide>
      <div
        className={css(
          styles.buttonContainer,
          styles.buttonFlex,
          styles.justifyCenter
        )}
      >
        <div className={css(styles.flexSpacebetween)}>
          <div className={css(styles.buttonFlex)}>
            <button
              onClick={() => {
                sendEvent(
                  `Prod ${props.productCode} Catalogue clicked`,
                  'Product Viewer',
                  'View Catalogue'
                )
                openURLNewTab(
                  'https://www.godrej.com/godrej-locks-and-security-solutions/Locks'
                )
              }}
              // className={css(styles.cardButton,buttonStylesV2.gradientBluePink,buttonStylesV2.buttonActive)}
              className={css(styles.cardButton, buttonStylesV2.buttonActive)}
            >
              View Catalogue
            </button>
            <button
              onClick={() => {
                sendEvent(
                  `Prod ${props.productCode} Shop clicked`,
                  'Product Viewer',
                  'Shop'
                )
                openURLNewTab(
                  'https://www.amazon.in/stores/page/5F225D0F-A7D0-4F3D-B869-9995364021EF?channel=godrej_com_buy_now'
                )
              }}
              className={css(styles.cardButton, buttonStylesV2.buttonActive)}
            >
              Shop
            </button>
          </div>
          {!model && (
            <button
              onClick={onClickViewAllProducts}
              className={css(
                styles.cardButton,
                styles.buttonLeft,
                buttonStylesV2.buttonActive
              )}
            >
              View All Products
            </button>
          )}
        </div>
      </div>
      {/* <div style={{ marginTop: '2vh' }}>
        <SimilarSlide slideWidth={width}>
          {similarProducts.slice(0, 20).map((prod, i) => {
            return (
              <NewCard
                onClick={() => handleClick(prod.productCode)}
                small
                img={thumbnailUrl + prod.productCode}
                key={i}
              />
            )
          })}
        </SimilarSlide>
      </div> */}
    </div>
  ) : null
}

// export const

const styles = StyleSheet.create({
  container: {
    // position: 'absolute',
    // width: '100%',
    // height: '70%',
    width: '100%',
    // boxShadow: '10px 10px 50px 3px #275C8D1A',
    opacity: 0.95,
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#333333',
    padding: '1vw',
    paddingLeft: '1.3vw',
    textAlign: 'right',
    boxSizing: 'border-box',
  },
  boxShadow: {
    boxShadow: '10px 10px 50px 3px #275C8D1A',
  },
  noBg: {
    backgroundColor: 'none',
    opacity: 1,
  },
  buttonContainer: {
    // position: 'absolute',
    textAlign: 'center',
    width: '93%',
    bottom: '1vw',
  },
  cardButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0.6vw 1.5vw 0.6vw 1.5vw',
    // height:'5vh',
    // width: '8vw',
    backgroundColor: 'grey',
    color: 'white',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: '1.8vh',
    borderRadius: '1.1vh',
    margin: 5,
    ':hover': {
      backgroundColor: '#818181',
    },
    ':active': {
      backgroundColor: '#313131',
    },
  },
  underline: {
    height: '0.1vw',
    backgroundColor: '#595757',
    marginLeft: '2vw',
  },
  heading: {
    textAlign: 'left',
    // float:'left',
    marginTop: '0vh',
    color: 'white',
    marginBottom: 0,
  },
  small: {
    color: '#777474',
  },
  smaller: {
    color: '#5C5959',
    marginTop: '2vh',
  },
  robotoBlack: {
    fontFamily: 'Arial',
    margin: '1%',
    fontWeight: 700,
    color: colorThemes.text[2],
    marginTop: '4%',
  },
  color: {
    fontFamily: 'Arial',
    fontWeight: 400,
    color: colorThemes.text[2],
    margin: 0,
    marginRight: '0.5%',
    opacity: 0.8,
  },
  line: {
    height: '1%',
    marginTop: '-1%',
    marginLeft: '-12%',
    overflow: 'visible',
    // left:'-50%',
    width: '100%',
    position: 'relative',
    top: 0,
    transform: 'rotate(180deg)',
  },
  collection: {
    // fontSize: '2vh',
    fontFamily: 'Work Sans',
    marginTop: '0.3vh',
    marginBottom: '1vh',
    fontWeight: 500,
    color: 'white',
    // color:'white'
  },
  productCode: {
    // fontSize: '1.38vh',
    // margin:0,
    textAlign: 'right',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    // marginTop:'-0.9vh',
    color: 'white',
    opacity: 0.6,
  },
  finish: {
    textAlign: 'left',
    // fontSize: '1.78vh',
    margin: 0,
    marginTop: '0.4vh',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    // marginTop:'-0.9vh',
    color: 'white',
    opacity: 0.7,
  },
  desc: {
    textAlign: 'left',
    marginTop: '3.7vh',
    // height:'5vh',
    overflow: 'hidden',
    color: 'white',
  },
  flexSpacebetween: {
    display: 'flex',
    // backgroundColor:'red',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '2vh',
  },
  buttonFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginTop:'2vh'
  },
  buttonLeft: {
    // float:'right',
    backgroundColor: 'rgba(0,0,0,0)',
    color: 'white',
    border: `solid ${colorThemes.textBlue} 0.14vw`,
    // marginTop:'2.01vh'
    // borderColor:g
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  featuresFlex: {
    display: 'flex',
    marginTop: '1vh',
    justifyContent: 'space-between',
  },
  features: {
    textAlign: 'left',
    // fontSize: '2.2vh',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    color: 'white',
  },
  featuresP: {
    margin: 0,
    color: 'white',
  },
  featureBox: {
    width: '15vw',
    boxSizing: 'border-box',
    borderRadius: '1vh',
    // padding:'1vh',
    textAlign: 'left',
    marginRight: '1vw',
    padding: '1vh 1vh 1.8vh 1vh',
    // height:'10vh',
    // border:'0.5px solid white',
    border: '1px solid #4F4F4F',
    // backgroundColor:'blue',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    // fontSize: '1.5vh',
  },
  featureH: {
    // fontSize: '1.6vh',
    // color:,
    margin: '0.5vh 0.4vh 0.6vh 0vh',
  },
  modelContainer: {
    paddingLeft: '6vh',
    paddingRight: '6vh',
    paddingTop: '2vh',
    paddingBottom: '3vh',
    backgroundColor: 'none',
    opacity: 1,
  },
  hideoverflow: {
    position: 'absolute',
    left: 0,
    top: '15%',
    // width:'3%',
    // backgroundColor:'red',
    backgroundColor: '#333333',
    // opacity:1,
    height: '30%',
    zIndex: 400,
  },
})
