import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useCameraStore } from '../../stores'
import { useEffect } from 'react'

export const ClickBlocker = (props) => {
    const cameraState = useCameraStore()
    useEffect(()=>{
        //console.log("ssssss",cameraState.tweening)
    },[cameraState.tweening])
    return (
        <div style={{zIndex:1,pointerEvents:cameraState.tweening?'auto':"none"}} className={css(styles.container)}>
            
        </div>
    )
}

const styles = StyleSheet.create({
    container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        // back
    }
})