import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSpring } from '@react-spring/core'
import { usePage } from '../../../../stores'
import { animated as a } from '@react-spring/web'
import { useHistory } from 'react-router'
import { useState } from 'react'
import { sendEvent } from '../../../../google-analytics'
export const CylinderOutsideHud = (props) => {
  const pageState = usePage()
  const history = useHistory()
  const spring = useSpring({
    opacity: pageState.page === 'cylinderoutside' ? 1 : 0,
  })
  const [pagePart, setPart] = useState(0)
  const opacitySpring = useSpring({ opacity: pagePart === 0 ? 1 : 0 })
  const handleClickNo = () => {
    history.push(`/cylinder?room=0`)
    sendEvent(`No button clicked`, 'Get Started', 'Get Started Button')
  }
  const handleClickYes = () => {
    history.push(`/categories`)
    sendEvent(`Yes button clicked`, 'Get Started', 'Get Started Button')
  }
  return (
    <a.div
      style={{
        opacity: spring.opacity,
        pointerEvents: pageState.page === 'cylinderoutside' ? 'auto' : 'none',
      }}
      className={css(styles.container)}
    >
      <div className={css(styles.content)}>
        <a.div style={opacitySpring}>
          <h1>Welcome to the Tour</h1>
          <button
            onClick={() => {
              setPart(1)
              sendEvent(
                `Get Started button clicked`,
                'Get Started',
                'Get Started Button'
              )
            }}
          >
            get started
          </button>
        </a.div>

        <a.div
          style={{
            opacity: opacitySpring.opacity.to((x) => 1 - x),
            zIndex: 120,
          }}
        >
          <h1 style={{ color: 'white' }}>ANYTHING SPECIFIC IN MIND?</h1>
          <button onClick={handleClickNo}>NO</button>
          <button onClick={handleClickYes}>YES</button>
        </a.div>
      </div>
      {/* <a.div style={{zIndex:1,pointerEvents:'none'}} className={css(styles.background)}></a.div> */}
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 100,
    // back
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.6,
  },
  content: {
    zIndex: 120,
    position: 'absolute',
  },
})
