import { useSpring } from "@react-spring/web";
import React from "react";
import { usePage } from "../../../../stores";
import { animated as a } from "@react-spring/web";
import { css, StyleSheet } from "aphrodite";
import Direction from "./Direction";
export const About = () => {
  const page = usePage();
  const spring = useSpring({
    opacity: page.page === "about" ? 1 : 0,
  });
  return (
    <>
    <a.div style={{...spring}} className={css(styles.bg)}></a.div>
      <a.div
        style={{
          ...spring,
          pointerEvents: page.page === "about" ? "auto" : "none",
        }}
      >
        <div className={css(styles.container)}>
          <h1 className={css(styles.heading)}>About Godrej Locks</h1>
          <p className={css(styles.para1)}>
          Godrej Locks is part of the Godrej Group, one of India’s leading organisations, with the 

patronage of 500 million users, for the last 125 years. This multi-location and 

multi-business corporate house has etched out a global footprint and is the choice of 

customers, worldwide.</p> <p className={css(styles.para1)}>

Godrej was the vision of a man with big dreams - Ardeshir Godrej - who started a small 

company manufacturing locks in 1897. Today, this company has grown to become a 

household name across India, touching lives in every corner of the country. It has 

expanded to become 4.1 billion USD conglomerate with well-established brands in 

diverse industries. All thanks to the company’s consistent vision of satisfying end users’ 

needs of security, safety, and convenience in India. 
</p>

<p className={css(styles.para1)}>
From locks, household appliances, furniture and food to real estate, hi-tech engineering, 

security and personal care, Godrej's relentless pursuit of excellence has enriched lives 

across the globe. So much so, that in the year 2014, Godrej Locks was awarded the 

CII-EXIM Bank Business Excellence Award, based on the European Foundation for Quality 

Management (EFQM) Model for Business Excellence. </p>
<p className={css(styles.para1)}>
Staying true to its core values of fairness, transparency and ethical dealing, the group has 

built a strong network of over 50 company-owned retail stores, more than 2,200 wholesale 

dealers and over 18,000 retail outlets. Moreover, Godrej Locks is a role model organisation 

in India as a responsible corporate citizen. </p>
<p className={css(styles.para1)}>
Godrej Locks follows the same tradition of excellence, and is well-known for its constant 

innovation blended with cutting edge technology. We offer a comprehensive range of 

choices in design and strengths of security, to anticipate every need for safety faced by 

contemporary homes and businesses. 
          </p>
          {/* <p className={css(styles.inline)}>
            <div>
              <Direction />
            </div>
            <div className={css(styles.right)}>
              <span className={css(styles.paraHead)}>Design concept</span>
              <p className={css(styles.subpara)}>
                Vitae nulla nunc euismod vel nunc euismod velpretium tellus
                accumsan nulla nunc euismod.
              </p>
            </div>
          </p>
          <p className={css(styles.inline)}>
            <div>
              <Direction />
            </div>
            <div className={css(styles.right)}>
              <span className={css(styles.paraHead)}>Design concept</span>
              <p className={css(styles.subpara)}>
                Vitae nulla nunc euismod vel nunc euismod velpretium tellus
                accumsan nulla nunc euismod.
              </p>
            </div>
          </p> */}
          {/* <div>
            <Index />
          </div> */}
        </div>
      </a.div>
    </>
  );
};

const styles = StyleSheet.create({
  bg:{
    position:'absolute',
    zIndex:1,
    height:'100vh',
    width:'100vw',
    top:0,
    left:0,
    background: 'rgb(42,42,42)',
background: 'linear-gradient(90deg, rgba(42,42,42,0.7710434515603116) 0%, rgba(255,255,255,0) 100%)',
  },
  container: {
    marginTop: "8vh",
    width: "90vh",
    position: "absolute",
    left: 0,
    paddingRight: "6.6vh",
    paddingLeft: "6.6vh",
    marginRight: "auto",
    marginLeft: "auto",
    zIndex:5,
  },
  heading: {
    fontSize: "6.4vh",
    color: "#DFDFDF",
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  para1: {
    fontFamily: "Montserrat",
    fontSize: "2vh",
    color: "#f4f4f4",
    letterSpacing: "0.015em",
  },
  paraHead: {
    fontSize: "2.5vh",
    fontFamily: "Montserrat",
    fontWeight: "700",
    textALign: "left",
    color: "#dfdfdf",
    lineHeight: "0",
  },
  subpara: {
    margin: "1vh 0vh",
    fontSize: "2vh",
    fontWeight: "400",
    letterSpacing: "-2%",
    color: "#ffffff",
  },
  inline: {
    display: "inline-flex",
  },
  right: {
    paddingLeft: "2vh",
  },
});
