import React, { useEffect } from 'react'
import { usePage } from '../../stores'
import { Fullscreen } from './fullscreen/Fullscreen'
import { HUD } from './HUD/HUD'

export const UIManager = () => {
    const pageState = usePage()
    useEffect(()=> {

    }, [pageState.page])
    return (
        <>
            <Fullscreen />
            <HUD />
        </>
    )
}
