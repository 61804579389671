import { Html } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useState, useRef } from 'react'
import { ConsoleView } from 'react-device-detect'
import { Vector3 } from 'three'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import {
  useCameraStore,
  useCustomFade,
  useLocalLoader,
  useNewLoadingScreen,
  usePage,
  useParallax,
  usePopup,
} from '../../stores'
import { PopupScreen } from '../UI/HUD/popup/PopupScreen'
// import { CameraTween } from './CameraTween'

const Tweener = React.memo(
  ({ object, to, time, fov, position, lookAt, shouldFade, pos, instant}) => {
    const { camera } = useThree()
    const first = useRef(true)
    const pageState = usePage()
    const regress = useThree(state=>state.performance.regress)
    const cameraState = useCameraStore()
    const { setMove } = useParallax()
    const {data} = usePopup()
    const { setStatus: setCustomFade } = useCustomFade()

    useEffect(() => {})

    useEffect(() => {
      let tween
      if (first.current) {
        if (fov) {
          camera.fov = to
        } else {
          camera.position.set(to)
        }
        first.current = false
        // console.log('first')
        return
      }
      cameraState.setTweening(true)
      // setParallax(true)
      if (fov) {
        tween = new TWEEN.Tween({ x: camera.fov > 60 ? 20 : camera.fov })
          .to({ x: to }, instant?1:time)
          .onUpdate((state) => {
            camera.fov = state.x
            camera.updateProjectionMatrix()
            // camera.
          })
          .easing(
            pageState.page === 'showcase'
              ? TWEEN.Easing.Cubic.In
              : TWEEN.Easing.Cubic.Out
          )

        // .onStop()
        tween.start()
      } else if (position) {
        tween = new TWEEN.Tween(object)
          .to(to, instant?1:time)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onStart(() => {})
          // .onUpdate(regress)
          .onComplete(() => {
            if(pos){
              setCustomFade(false)
              // setParallax(false)
              if(data.productCode) setMove(true) // if popup mounted only start parallax
              cameraState.setTweening(false)
              
            }
          })
        // .onStart(())
        // .onStop()
        tween.start()
      } else {
        tween = new TWEEN.Tween(object)
          .to(to, instant?1:time)
          .easing(TWEEN.Easing.Quadratic.InOut)
        // .onStart(()=>cameraState.setTweening(true))
        // .onComplete(()=>cameraState.setTweening(false))
        // .onStart(())
        // .onStop()
        tween.start()
        console.log(tween)
      }
      return () => tween && tween.stop()
      // //console.log('starting',to)
    }, [to])
    useFrame(() => {
      TWEEN.update()
      camera.updateProjectionMatrix()
    })
    return null
  }
)

export const TweenManager = React.memo((props) => {
  const { camera } = useThree()
  const cameraState = useCameraStore()
  const [cameraInt, setCameraInt] = useState(new Vector3(0, 0, 0)) //intermidate camera position
  const [fovInt, setFovInt] = useState(50)
  const isFirstRender = useRef(true)
  const parallax = useParallax()
  const {isLoading} = useNewLoadingScreen()
  const page = usePage()
  const popup = usePopup()
  const [lookAtInt, setLookAtInt] = useState(new Vector3(0, 0, 0))
  const [dynamicTime, setTime] = useState(2000)
  const loader = useLocalLoader()
  const [delayedInstant, setDelayedInstant] = useState(isLoading)
  // console.log('tween manager reredner')
  const timeout = useRef()
  useEffect(() => {
    if(isLoading){
      setDelayedInstant(isLoading)
    }else{
      timeout.current = setTimeout(() => setDelayedInstant(isLoading),[1000])
    }
    return () => clearInterval(timeout.current)
  }, [isLoading])
  useEffect(() => {
    // setTimeout(()=> console.log('pwepwewpe'),[4000])
    // setTimeout(() => {
      // if (loader.progress === 100 || isFirstRender.current) {
      //   if (isFirstRender.current) {
      //     isFirstRender.current = false
      //     setCameraInt(cameraState.position)
      //     setFovInt(cameraState.fov)
      //     setLookAtInt(cameraState.lookAt)
      //     setTime(100)
      //   } else {
          setTimeout(() => {
            setCameraInt(cameraState.position)
            setFovInt(cameraState.fov)
            setLookAtInt(cameraState.lookAt)
            setTime(cameraState.time?cameraState.time:cameraState.fov === camera.fov ? 2000 : 3000)

            // //console.log('time',camera.position.distanceTo(cameraState.position)*0.5/Math.pow(camera.fov!==cameraState.fov?(camera.fov-cameraState.fov)/5:1,1))
          }, [300])
      //   }
      // }
    // }, 50)
  }, [cameraState])
  // useEffect(()=>{
  //     if(loader.progress ===)
  // },[loader.progress])
  return (
    <>
      <Tweener
        position
        // {...props}
        pos
        object={camera.position}
        to={cameraInt}
        // time={4000}
        instant={isLoading || delayedInstant}
        time={dynamicTime}
      />
      {props.controls.current && (
        <Tweener
          // object={camera.fov}
          // {...props}
          fov
          to={fovInt}
          instant={isLoading || delayedInstant}
          time={dynamicTime}
          // time={3000}
        />
      )}
      {props.controls.current && (
        <Tweener
          lookAt
          object={props.controls.current.target}
          instant={isLoading || delayedInstant}
          // object={camera.fov}
          to={lookAtInt}
          time={(page.page==='showcase' && popup.popupId!==null)?dynamicTime:dynamicTime}
          // time={4000}
        />
      )}
    </>
  )
})
