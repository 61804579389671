import { useProgress } from '@react-three/drei'
import React, { useEffect } from 'react'
import { useNewLoadingScreen } from '../../stores'

export const InsideSuspense = () => {
    const {progress} = useProgress()
    const newLoader = useNewLoadingScreen()
    useEffect(() => {
        newLoader.setProgress(progress)
    },[progress])
  return (
    <></>
  )
}
