import { Html } from "@react-three/drei";
import { useControls } from "leva";
import React, { useEffect, useState } from "react";
import {
  useCameraStore,
  usePage,
  usePopup,
  useWorkaround,
} from "../../../../stores";
import { animated as a } from "@react-spring/web";
import { useSpring } from "@react-spring/core";
import { css, StyleSheet } from "aphrodite";
import { isMobile } from "react-device-detect";
import { sendEvent } from "../../../../google-analytics";

export function findProductCodeInArray(code, array) {
  for (var i in array) {
    if (array[i].productCode == code) return array[i];
  }
  return null;
}

export const SpawnPoint = (props) => {
  const workaround = useWorkaround();
  const pageState = usePage();
  const cameraState = useCameraStore();
  const [hover, setHover] = useState(false);

  const popup = usePopup();
  const {
    fov,
    point_position: { x, y, z },
    print_point,
  } = useControls({
    fov: { value: 45, step: 1 },
    point_position: { value: { x: 0, y: 0, z: 0 }, step: 0.025 },
    print_point: { value: false },
  });

  function handleClick() {
    sendEvent(`point ${props.id} Clicked`, "Spawn Point Manager", "Point");
    workaround.setType(1); //SpawnPoint Type = 1
    workaround.setPopup(-1);
    workaround.setPopup(props.id);
  }

  useEffect(() => {
    print_point && console.log(`position:[${x},${y},${z}]`);
  }, [print_point]);

  const { opacity, opacity2 } = useSpring({
    from: { opacity: 0, opacity2: 0 },
    opacity:
      pageState.page === "showcase" &&
      popup.data.popupId == null &&
      cameraState.tweening == false
        ? 1
        : 0,
    opacity2: hover ? 1 : 0,
    delay: 0,
  });

  function handleHover(stat) {
    setHover(stat);
  }

  return (
    <group position={props.position}>
      <Html
        zIndex={1}
        style={{
          position: "absolute",
          zIndex: 1,
          height: "5vh",
          pointerEvents: "none",
        }}
        zIndexRange={[3, 1]}
      >
        <a.div
          style={{
            opacity,
          }}
        >
          <img
            onPointerDown={handleClick}
            onPointerOver={() => handleHover(true)}
            onPointerOut={() => handleHover(false)}
            src={props.img}
            className={css(!hover ? style.pulseAnimation : "")}
            style={{
              marginTop: "6.5vh",
              height: isMobile ? "5vh" : "3.5vh",
              cursor: "pointer",
              pointerEvents: "auto",
              rotate: "1 -0.1 0.3 45deg",
            }}
          />
        </a.div>
      </Html>
    </group>
  );
};

const opacityKeyframes = {
  from: {
    opacity: 1,
    transform: "scale(0.9)",
  },

  to: { opacity: 0.5, transform: "scale(0.8)" },
};

const style = StyleSheet.create({
  pulseAnimation: {
    animationName: [opacityKeyframes],
    animationDuration: "1s, 1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
  },
});

export const SpawnPointManager = (props) => {
  //console.log(props)

  return props.spawnpoints?.map((spawnpoint, i) => {
    return <>{<SpawnPoint key={i} {...spawnpoint} />}</>;
  });
};
