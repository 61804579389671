import React, { useEffect, useMemo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { usePage, useProducts, useSearch } from '../../../../stores'
import { colorThemes, textStylesV2 } from '../../../../appStyles'
import { Slide } from './components/Slide'
import { useHistory } from 'react-router'
import { Card } from './components/Card'
import { categories } from '../../../../constants'
import { NewCard } from './components/NewCard'
import { useMeasure, useWindowSize } from 'react-use'
import { SearchBar } from './components/SearchBar'
import { thumbnailUrl } from '../../../../cms/cmsstore'
import { sendEvent } from '../../../../google-analytics'
// const exampleCategories = new Array(8).fill({thumbnail:'https://picsum.photos/600/600'})

export const Categories = (props) => {
  const pageState = usePage()
  // const {opacity} = useSpring( {opacity:pageState.page==='categories'?1:0} )
  const history = useHistory()
  const { search } = useSearch()
  const productStore = useProducts()
  const handleClick = (i) => {
    // //console.log('click',i)
    sendEvent(
      `Categories Product ${i} clicked`,
      'Categories',
      'Catalog_Categories'
    )
    // history.push(`/products?id=${i}`)
    console.log('clicked')
    if (search) {
      history.push(`/mv?id=${i}`)
    } else {
      history.push(`/catalog?category=${i}`)
    }
  }
  const [show, setShow] = useState(false)
  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: pageState.page === 'categories' ? 1 : 0,
    onRest: () => pageState.page !== 'categories' && setShow(false),
  })
  const { width } = useWindowSize()
  useEffect(() => {
    if (pageState.page === 'categories') setShow(true)
  }, [pageState.page])
  const searchProducts = useMemo(() => {
    if (!search) return categories
    let prods = productStore.products.filter(
      (prod) => prod.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    return prods.sort(
      (x, y) =>
        x.name.toLowerCase().indexOf(search.toLowerCase()) -
        y.name.toLowerCase().indexOf(search.toLowerCase())
    )
  }, [search])
  // let categories = content?content:exampleCategories
  return (
    show && (
      <a.div
        style={{
          opacity,
          // pointerEvents: pageState.page === 'categories' ? 'auto' : 'none',
        }}
        className={css(styles.container)}
      >
        <div className={css(styles.grey)}></div>
        {/* <div className={css(styles.backButtonContainer)}>
          <button className={css(styles.backButton)}>Back</button>
        </div> */}
        {/* <div className={css(styles.headerContainer)}>
          <div className={css(textStylesV2.LabelL, textStylesV2.GodrejGreen)}>
            CATEGORIES
          </div>
          <div
            className={css(
              textStylesV2.HeadlineM,
              textStylesV2.BasicColor,
              styles.header
            )}
          >
            What are you looking for ?
          </div>
        </div> */}
        <SearchBar />
        <Slide slideWidth={width} elementCount={searchProducts.length}>
          {searchProducts.map((item, i) => (
            <NewCard
              onClick={() => handleClick(search ? item.productCode : i)}
              img={
                !search
                  ? searchProducts[i]?.thumbnail
                  : thumbnailUrl + searchProducts[i].productCode
              }
              name={searchProducts[i]?.name}
              category={search ? false : true}
            />
          ))}
        </Slide>
      </a.div>
    )
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    marginLeft: '5vw',
    marginTop: '14vh',
  },
  header: {
    marginTop: '1vh',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#000000A4',
    zIndex: 50,
    overflow: 'hidden',
  },
  backButtonContainer: { textAlign: 'right' },
  backButton: {
    background: 'none',
    outline: 'none',
    border: 'none',
    fontSize: 24,
    color: 'white',
    paddingTop: '5vh',
    paddingRight: '3vh',
  },
  grey: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    top: 0,
    left: 0,
    zIndex: 21,
    pointerEvents: 'none',
  },
})
