import React from 'react'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { sendPageView } from '../../google-analytics'
import { useWorkaround } from '../../stores'

function getParams(searchStr) {
  let par = searchStr.slice(1, searchStr.length).split('&')
  let res = {}
  par.forEach((x) => (res[x.split('=')[0]] = x.split('=')[1]))
  Object.keys(res).forEach((x) => (res[x] = Number(res[x])))
  return res
}

export const WorkAround = () => {
  const history = useHistory()
  const location = useLocation()
  const workaround = useWorkaround()
  useEffect(() => {
    // //console.log('workaround push',workaround.url)
    if (workaround.url !== '') workaround.url && history.push(workaround.url)
  }, [workaround.url])
  useEffect(() => {
    let params = getParams(location.search)
    console.log('Animation- Setting Type to ',params.type)
    workaround.setPopup('')
    workaround.setType(params.type);
  }, [location])
  useEffect(() => {
    let params = getParams(location.search)
    //console.log(workaround.popup, 'op')
    sendPageView(
      `Product Popup ${workaround.popup}`,
      'Product Popup',
      `Product ${params.room}`
    )
    workaround.popup !== '' &&
      history.push(`/showcase?popup=${workaround.popup}&room=${params.room}&type=${workaround.type}`)
  }, [workaround.popup])

  return null
}
