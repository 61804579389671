import { useEffect } from "react"
import { usePopup, useWorkaround } from "../../../stores"
import * as THREE from 'three'
import { useFrame } from "@react-three/fiber"

var previous;

function playAnimationForward(anim) {
    if(!anim) return
    console.log(anim)
    anim.loop = THREE.LoopOnce
    anim.blendMode = THREE.AdditiveAnimationBlendMode 
    anim.timeScale = 1
    anim.clampWhenFinished = true
    anim.paused = false
    // anim.weight = 1000
    anim.play()
  }

  function playAnimationBackward(anim) {


    if(!anim) return
    anim.loop = THREE.LoopOnce
    anim.blendMode = THREE.AdditiveAnimationBlendMode
    anim.clampWhenFinished = true
    anim.timeScale = -1
    anim.paused = false
    // anim.weight = 1000
    anim.play()
  }

export const RoomAnimations = ({animations, room, mixer}) => {
    const popup = usePopup()
    const workaround = useWorkaround()

    //If the room changes this resets all the animations of the current page
    useEffect(() =>{
        mixer.stopAllAction();
    },[room])

    useFrame(() =>{
        
        popup.actions?.map(action => {
            if(Object.keys(animations).includes(action)){
                console.log("Animation-", animations[action].isRunning())
        // console.log(animations[action].time)
            }
        })
    })
    useEffect(()=>{
        console.log('Animation- popup DATA ', popup.data);
        console.log('Animation- workaround ', workaround);
        // console.log('Animation Data',animations);
        if(((!popup.data.popupId && popup.data.popupId!=0)|| (workaround.type === 1 && popup.data.popupId !== -1)) && animations){
            console.log("Animation- WHICH BACK DOES THIS PLAY ON ? ")
            Object.keys(animations)?.map(anim=>{
                THREE.AnimationUtils.makeClipAdditive(animations[anim].getClip());
                playAnimationBackward(animations[anim])
            })
        }
        else if (animations){
            Object.keys(animations)?.map(anim=>{
                THREE.AnimationUtils.makeClipAdditive(animations[anim].getClip());
                playAnimationBackward(animations[anim])
            })
            
            popup.actions?.map(action => {
                console.log('playing ',action, animations[action])
                if(Object.keys(animations).includes(action)){
                    THREE.AnimationUtils.makeClipAdditive(animations[action].getClip());
                    console.log("Running playanimation forward!", animations[action])
                    playAnimationForward(animations[action])}
                    // previous = animations[action]
            })

        }
    },[popup.data,popup.actions,workaround.type])
    return null
}