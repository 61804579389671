import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { ArrowLeft, ArrowRight } from '../../../../assets/svgs/Arrows'
import { useDrag } from 'react-use-gesture'
import { isMobile } from 'react-device-detect'
import { useMeasure } from 'react-use'
import { usePopup } from '../../../../../stores'
export const Slide = ({ children, elementCount, slideWidth, ...props  }) => {
  const styles = StyleSheet.create({
    container: {
      top:'53%',
      display: 'inline-flex',
      // flex:'auto',
      // justifyContent: "space-around",
      justifyContent: 'flex-start',
      position: 'absolute',
      boxSizing: 'border-box',
      height: !props.small ? '56%' : '100%',
        // backgroundColor:'red',
      zIndex: 0,
      overflow: 'visible',
    },
    smallContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      position: 'absolute',
      // width: '100%',
      // backgroundColor:'red',

      height: '90%',
      top: 0,
      left: 5,
      // transform:'translateY()'
      overflowY: 'visible',
      // backgroundColor:'yellow'
    },
  })
  // //console.log(children)
  let factor = isMobile ? 4/4 : 3/4
  const [page, setPage] = useState(0)
  const [mousedown, setMousedown] = useState(false)
  const [ref,{width}] = useMeasure()
  const popup = usePopup()
  let pageWidth = slideWidth - slideWidth*20/100
  let swipeThreshold = (window.innerWidth * (props.small?20:10)) / 100
  const [{ transform }, api] = useSpring(() => ({
    transform: `translate(${0}vw,${!props.small ? '-50%' : '0%'}`,
  }))
  useEffect(()=>{
    setPage(0)
  },[popup.data])
  const bind = useDrag(
    ({ down, movement: [x], cancel }) => {
      // console.log(down,mx);
      let mx = factor * x
      api.start({
        transform: down
          ? `translate(${
              page === 0
                ? mx
                : page *
                  // -(!props.small
                    // ? 80 + (10 * -mx) / window.innerWidth
                    // : 28 + -mx / 50)
                      -(pageWidth) + mx
            }px,${!props.small ? '-50%' : '0%'}`
          : `translate(${page * -(pageWidth)}px,${
              !props.small ? '-50%' : '0%'
          }`,
        // transform: down
        //   ? `translate(${
        //     page*pageWidth + mx
        //   }px,${!props.small ? -50 : 0}%)`
        //   : `translate(${
        //     page*pageWidth
        //   }px,${!props.small ? -50 : 0}%)`
        //  , immediate: down
      })
      if (mx > swipeThreshold && !down)
        setPage((state) => (page > 0 ? page - 1 : page))
      if (mx < -swipeThreshold && !down)
        setPage((state) =>
          (page+1)*pageWidth < width-pageWidth*30/100 ? page + 1 : page
        )
    },
    { preventDefault: true, filterTaps: true }
  )

  useEffect(() => {
    api.start({
      transform: `translate(${page * -(pageWidth)}px,${
        !props.small ? -50 : 0
      }%`,
    })
  }, [page])
  useEffect(() => {
    window.addEventListener('mousedown', () => setMousedown(true))
    window.addEventListener('mouseup', () => setMousedown(false))
    return () => {
      window.removeEventListener('mousedown', () => setMousedown(true))
      window.removeEventListener('mouseup', () => setMousedown(false))
    }
  }, [])
  return (
    <>
      <a.div
        style={{ transform }}
        {...bind()}
        ref={ref}
        className={
          props.small ? css(styles.smallContainer) : css(styles.container)
        }
      >
        {children}
      </a.div>
      <div style={{}}>
        <ArrowLeft
          style={
            props.small
              ? {
                  position: 'absolute',
                  width: '4.5vh',
                  zIndex: 100,
                  right: '83.6%',
                  bottom: '-2%',
                }
              : {
                  position: 'absolute',
                  width: '2.2vw',
                  zIndex: 100,
                  left: '45%',
                  bottom: '1%',
                }
          }
          onClick={() => setPage(page > 0 ? page - 1 : page)}
        />
        <ArrowRight
          style={
            props.small
              ? {
                  position: 'absolute',
                  bottom: '-2%',
                  left: '18.6%',
                  width: '4.5vh',
                }
              : {
                  position: 'absolute',
                  width: '2.2vw',
                  zIndex: 100,
                  right: '45%',
                  bottom: '1.016%',
                }
          }
          onClick={() => {
            // setPage(page < Math.floor(elementCount / 4) ? page + 1 : page)
            setPage((page+1)*pageWidth < width-pageWidth*30/100 ? page + 1 : page)
          }}
        />
      </div>
    </>
  )
}
