import { css, StyleSheet } from "aphrodite";
import { useQR } from "../../stores";
import QRCode from "react-qr-code";
import { useEffect, useRef } from "react";

export const QRModal = () => {
  const qr = useQR();
  const ref = useRef();

  const handleClickOutside = (e) => {
    if (e.target !== ref.current && qr.url) {
      qr.setQR("");
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div
      style={{ pointerEvents: qr.url ? "auto" : "none" }}
      className={css(styles.container)}
    >
      <div ref={ref} className={css(styles.smallContainer)}>
        <h1 className={css(styles.scanMe)}>Scan me for AR!</h1>
        <QRCode
          fgColor="black"
          bgColor="white"
          width="100%"
          height="100%"
          value={qr.url}
        />
        <h1 className={css(styles.close)}>
          <span className={css(styles.info)}>*</span> Tap outside to close
        </h1>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  smallContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    backgroundColor: "grey",
    padding: "1vh 2vh 1vh 2vh",
    borderRadius: "2vh",
    transform: "scale(1.3) translate(-50%,-45%)",
    textAlign: "center",
  },
  scanMe: {
    color: "white",
    fontSize: "2.2vh",
  },
  container: {
    backgroundColor: "rgba(0,0,0,0.8)",

    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
  },
  info: {
    color: "red",
    fontSize: "1.1em",
  },
  close: {
    textAlign: "right",
    fontSize: "0.7em",
    color: "white",
  },
});
