import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { usePage, useShouldMount } from "../../../stores";
import { Showcase } from "../showcase/Showcase";
import { BackDrop } from "./BackDrop";
import { Cylinder } from "./Cylinder";
import { baseUrl } from "../../../cms/cmsstore";
import axios from "axios";
import { RotatingGroup, WobblyGroup } from "./WobblyFloor";
import Skybox from "../skybox/Skybox";
import { SuspenseLoader } from "../../loading/SuspenseLoader";
import { useFrame, useThree } from "@react-three/fiber";
import { CineonToneMapping, ReinhardToneMapping } from "three";
import { Reflector } from "./Reflector";
import { Box } from "@react-three/drei";
import StaticSkybox from "../skybox/StaticSkybox";
import { DebugValues } from "./DebugValues";
import { InsideSuspense } from "../../loading/InsideSuspense";
export const Environment = (props) => {
  const pageState = usePage();
  const { camera, gl } = useThree();
  const [skyboxNames, set] = useState([]);
  const shouldMount = useShouldMount();
  const [show, setShow] = useState(false);
  useFrame(() => {
    // //console.log(camera.position)
  });
  useEffect(() => {
    setTimeout(() => setShow(true), [1000]);
  }, []);
  useEffect(() => {
    gl.toneMapping = CineonToneMapping;
    const fetchSkyboxNames = async () => {
      try {
        const res = await axios.get(baseUrl + "/skybox");
        set(res.data.map((x) => x.name));
      } catch (err) {}
    };
    fetchSkyboxNames();
  }, []);
  return (
    // <BrowserRouter>
    <>
      {show && (
        <>
          {shouldMount.cylinder && (
            <>
              <Suspense fallback={<InsideSuspense />}>
                <WobblyGroup>
                  <RotatingGroup>
                    <Cylinder />
                    {/* <BackDrop /> */}
                  </RotatingGroup>
                </WobblyGroup>
                <Reflector />
                <BackDrop />
                <fog args={["#190f28", 10, 100]} attach="fog" />
              </Suspense>
            </>
          )}
          {shouldMount.showcase && <Showcase />}
          <StaticSkybox />
          {/* <DebugValues parentDiv={props.parentDiv}/> */}
          {/* {skyboxNames.length !== 0 && <Skybox skyboxNames={skyboxNames} />} */}
        </>
      )}
    </>
    // </BrowserRouter>
  );
};
