import { useEffect } from "react";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { PMREMGenerator, UnsignedByteType } from "three";
import { useThree } from "@react-three/fiber";
import { baseUrl } from "../cmsstore";

export default function DynamicSkybox({ hdri }) {
  const { gl, scene } = useThree();

  useEffect(() => {
    //console.log('hdri',hdri)
    let pmremGenerator = new PMREMGenerator(gl);
    pmremGenerator.compileEquirectangularShader();
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath(baseUrl + '/skybox/')
      .load(hdri, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        scene.environment = envMap;

        texture.dispose();
        pmremGenerator.dispose();
      });
  }, [gl, scene, hdri]);

  return null;
}