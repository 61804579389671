import React from "react";

export const CmsLoading = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "10%",
        overflow: "hidden",
        zIndex: 120,
        top: 0,
        left: 0,
        textAlign: "center",
        pointerEvents: "none",
      }}
    >
      {props.loading === 2 ? (
        <p style={{ color: "green" }}>Successful...</p>
      ) : props.loading === 1 ? (
        <p style={{ color: "yellow" }}>Waiting for response...</p>
      ) : props.loading === -1 ? (
        <p style={{ color: "red" }}>Could not get response...</p>
      ) : null}
    </div>
  );
};
