import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { MathUtils } from 'three'
import { debugCamera } from '../../constants'
import {
  useCameraStore,
  useCustomFade,
  usePage,
  useParallax,
  usePopup,
} from '../../stores'

let lastFrame = 0
export default function ParallaxCamera() {
  const { camera } = useThree()
  const cameraState = useCameraStore()
  const { status } = useCustomFade()

  const mouse = useRef()
  const pageState = usePage()
  const popup = usePopup()
  // const [canParallax, setCanParallax] = useState(false)
  // const { canParallax: storeParallax } = useParallax()
  const parallax = useParallax()
  function onMove(e) {
    let temp = {
      x: e.clientX / window.innerWidth,
      y: e.clientY / window.innerHeight,
    }
    mouse.current = temp
  }

  // useEffect(() => {
  //   if (Object.keys(popup.data).length === 0) {
  //     setCanParallax(false)
  //   } else {
  //     setTimeout(() => {
  //       setCanParallax(true)
  //     }, 1000)
  //   }
  // }, [popup])

  useEffect(() => {
    if (pageState.page === 'showcase')
      window.addEventListener('mousemove', onMove)

    return function cleanup() {
      window.removeEventListener('mousemove', onMove)
    }
  }, [pageState])
  let parallaxFactor = 1/15
  useEffect(()=>{
    console.log('parala',parallax.shouldMove)
  },[parallax.shouldMove])
  useFrame((state) => {
    // console.log(Object.keys(popup.data).length)
    let deltaTime = state.clock.elapsedTime - lastFrame
    if (parallax.shouldMove) {
    // if () {
      if (!debugCamera) {
        if (!cameraState.tweening && !status && mouse.current != null) {
          // console.log(state.clock)
          // console.log(mouse.current)
          let targetX = cameraState.position.x - mouse.current.x * parallaxFactor
          let targetY = cameraState.position.y - mouse.current.y * parallaxFactor
          let targetZ = cameraState.position.z - mouse.current.x * parallaxFactor
          camera.position.x = MathUtils.lerp(
            camera.position.x,
            targetX,
            deltaTime
          )
          // camera.position.z = MathUtils.lerp(camera.position.z, targetZ, deltaTime)
          camera.position.y = MathUtils.lerp(
            camera.position.y,
            targetY,
            deltaTime
          )
        }
      }
    }
    lastFrame = state.clock.elapsedTime
  })
  return null
}
