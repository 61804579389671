import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Slide } from '../../fullscreen/pages/components/Slide'
import { Card } from '../../fullscreen/pages/components/Card'
import { useHistory } from 'react-router'
import { thumbnailUrl } from '../../../../cms/cmsstore'
import { NewCard } from '../../fullscreen/pages/components/NewCard'
import { useMeasure } from 'react-use'
export const SimilarProducts = (props) => {
  const history = useHistory()
  const handleClick = (prodCode) => {
    history.push(`/mv?id=${prodCode}`)
  }
  const [ref,{width}] = useMeasure()
  return (
    <div ref={ref} className={css(styles.container)}>
      <Slide slideWidth={width} products={props.products} small elementCount={props.products.length}>
        {props.products.map((product, i) => {
          return (
            <NewCard
              small
              onClick={() => handleClick(product.productCode)}
              img={thumbnailUrl +  product.productCode }
            />
          )
          // if(i%3==0){
          //     return (
          //         // <Page small>
          //         <>
          //             <Card small onClick={() => handleClick(props.products[i])} img={props.products[i]?.thumbnail}/>
          //             <Card small onClick={() => handleClick(props.products[i+1])} img={props.products[i+1]?.thumbnail}/>
          //             <Card small onClick={() => handleClick(props.products[i+2])} img={props.products[i+2]?.thumbnail}/>
          //         </>
          //         // </Page>
          //     )
          // }
        })}
      </Slide>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '105%',
    height: '100%',
    overflow: 'hidden',
    // overflowY:'visible',

    // backgroundColor:'red'
  },
})
