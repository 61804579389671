import React, { useEffect } from 'react'
import { textStylesV2 } from '../../../../../appStyles'
import { css, StyleSheet } from 'aphrodite'
import { usePage, useSearch } from '../../../../../stores'
export const SearchBar = ({ catalog }) => {
  const { search, setSearch } = useSearch()
  const { page } = usePage()
  // useEffect(() => {
  //     setSearch('')
  // },[page])
  return (
    <div className={css(styles.headerContainer)}>
      <div className={css(textStylesV2.LabelL, textStylesV2.GodrejGreen)}>
        {catalog ? 'PRODUCTS' : 'CATEGORIES'}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '1vh',
          // background: 'red',
        }}
      >
        <input
          className={css(
            textStylesV2.HeadlineM,
            textStylesV2.BasicColor,
            styles.header
          )}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={'What are you looking for?'}
        />
        <div style={{ marginLeft: '-2%' }}>
          <svg
            width="2.3vw"
            height="2.3vw"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="23.5" fill="#747474" stroke="white" />
            <circle
              cx="20.6665"
              cy="20.6667"
              r="8"
              stroke="white"
              stroke-width="1.2"
            />
            <path
              d="M20.6665 16.6667C20.1412 16.6667 19.6211 16.7701 19.1358 16.9711C18.6505 17.1722 18.2095 17.4668 17.8381 17.8382C17.4666 18.2097 17.172 18.6506 16.971 19.1359C16.77 19.6212 16.6665 20.1414 16.6665 20.6667"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
            />
            <path
              d="M32.6665 32.6667L28.6665 28.6667"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      {/* What are you looking for ?
      </div> */}
    </div>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    marginLeft: '5vw',
    marginTop: '12vh',
    // pointerEvents:'auto'
  },
  header: {
    // marginTop: '1vh',
    color: '#f3f3f3',
    marginLeft: '-2.2vh',
    width: '20vw',
    borderRadius: '4vw',
    fontSize: '2vh',
    paddingLeft: '2.2vh',
    paddingRight: '5vh',
    backgroundColor: '#626263',
    border: 'none',
    outline: 'none',
  },
})
