import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { ArrowLeft, ArrowRight, FeatureArrowLeft, FeatureArrowRight } from '../../../../assets/svgs/Arrows'
import { useDrag } from 'react-use-gesture'
import { isMobile } from 'react-device-detect'
import { useMeasure } from 'react-use'
import { usePopup } from '../../../../../stores'
export const SimilarSlide = ({ children, small=true, elementCount, slideWidth, ...props  }) => {
    props.small=true
  const styles = StyleSheet.create({
    container: {
        pointerEvents:'auto',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      overflowX:'hidden',
      zIndex: 25,
      overflow: 'visible',
    },
    smallContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      position: 'absolute',
      height: '90%',
      top: 0,
      left: 5,
      overflowY: 'visible',
    },
    arrowRight:{
        // position:'r',
        height:'4vh',
        top:0,
        left:0
    },
    arrowLeft:{
        position:'relative',
        height:'4vh',
        marginRight:'0.3vw'

    }
  })
  let factor = isMobile ? 4/4 : 1/4
  const [page, setPage] = useState(0)
  const [mousedown, setMousedown] = useState(false)
  const [ref,{width}] = useMeasure()
  
  const popup = usePopup()
  let pageWidth = slideWidth - slideWidth*20/100
  let swipeThreshold = (window.innerWidth * (1)) / 100
  const [{ transform }, api] = useSpring(() => ({
    transform: `translate(${0}vw,${!props.small ? '-50%' : '0%'}`,
  }))
  useEffect(()=>{
    setPage(0)
  },[popup.data])
  const bind = useDrag(
    ({ down, movement: [x], cancel }) => {
      let mx = factor * x
      api.start({
        transform: down
          ? `translate(${
              page === 0
                ? mx
                : page *
                      -(pageWidth) + mx
            }px,${!props.small ? '-50%' : '0%'}`
          : `translate(${page * -(pageWidth)}px,${
              !props.small ? '-50%' : '0%'
          }`,
      })
      if (mx > swipeThreshold && !down)
        setPage((state) => (page > 0 ? page - 1 : page))
      if (mx < -swipeThreshold && !down)
        setPage((state) =>
          (page+1)*pageWidth < width-pageWidth*30/100 ? page + 1 : page
        )
    },
    { preventDefault: true, filterTaps: true }
  )

  useEffect(() => {
    api.start({
      transform: `translate(${page * -(pageWidth)}px,${
        !props.small ? -50 : 0
      }%`,
    })
  }, [page])
  useEffect(() => {
    window.addEventListener('mousedown', () => setMousedown(true))
    window.addEventListener('mouseup', () => setMousedown(false))
    return () => {
      window.removeEventListener('mousedown', () => setMousedown(true))
      window.removeEventListener('mouseup', () => setMousedown(false))
    }
  }, [])
  console.log(children)
  return (
    <>
      
      <a.div
        style={{ transform }}
        {...bind()}
        ref={ref}
        className={
          css(styles.container)
        }
      >
        {children}
      </a.div>
      {children && <div style={{display:'flex',marginLeft:'0.3vw',marginTop:'1.9vh'}}>
        <FeatureArrowLeft
          className={css(styles.arrowLeft)}
          onClick={() => setPage(page > 0 ? page - 1 : page)}
        />
        <FeatureArrowRight
          className={css(styles.arrowRight)}
          onClick={() => {
            // setPage(page < Math.floor(elementCount / 4) ? page + 1 : page)
            setPage((page+1)*pageWidth < width-pageWidth*30/100 ? page + 1 : page)
          }}
        />
      </div>}
    </>
  )
}
