import React, { useEffect, useMemo, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import {
  getSimilarProducts,
  usePage,
  useProducts,
  useSearch,
} from '../../../../stores'
import { colorThemes } from '../../../../appStyles'
import { Slide } from './components/Slide'
import { useHistory } from 'react-router'
import { Card } from './components/Card'
import { thumbnailUrl } from '../../../../cms/cmsstore'
import { NewCard } from './components/NewCard'
import { useWindowSize } from 'react-use'
import { rooms } from '../../../../constants'
import { SearchBar } from './components/SearchBar'
import { sendEvent } from '../../../../google-analytics'
const exampleCategories = new Array(8).fill({
  thumbnail: 'https://picsum.photos/600/600',
})

export const Catalog = ({ content, ...props }) => {
  const productStore = useProducts()
  const pageState = usePage()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const { width } = useWindowSize()
  const { category, search } = useSearch()
  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: pageState.page === 'catalog' ? 1 : 0,
    onRest: () => pageState.page !== 'catalog' && setShow(false),
  })
  // useEffect(() => { // for getting all products
  //   rooms.map( (room,i) => {
  //     if(i===0)return
  //     let mainprods = room.hotspots.map(x =>  {
  //       let prod = productStore.products.find(y => (y.productCode === x.productCode))
  //       if(prod){
  //         let asd = getSimilarProducts(
  //           prod.tags,
  //           productStore.products,
  //           prod.productCode
  //         ).map(x => x.productCode)
  //         // console.log(asd)
  //         return asd

  //       }

  //     })
  //     // console.log( mainprods)
  //     console.log(mainprods.reduce((x,y) => y && typeof y==='object'?[...x,...y]:[],[]))
  //     // let se
  //   })
  // }, [])
  const catalogprods = useMemo(() => {
    let room = rooms[Number(category) + 1]
    let seen = {}
    let uniqueProducts = productStore.products.filter((prod) => {
      if (!seen[prod.name]) {
        seen[prod.name] = 1
        return true
      }
      return false
    })
    if (search)
      return uniqueProducts
        .filter(
          (prod) => prod.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
        .sort(
          (x, y) =>
            x.name.toLowerCase().indexOf(search.toLowerCase()) -
            y.name.toLowerCase().indexOf(search.toLowerCase())
        )

    return room.allproducts
      .map((code) => uniqueProducts.find((x) => x.productCode === code))
      .filter((x) => x !== undefined)
    // console.log(asd)
    // return []
  }, [category, productStore.products, search])
  // const searchProducts = useMemo(() => {
  //   if(!search)return categories
  //   return productStore.products.filter(prod => prod.name.indexOf(search)!==-1)
  // },[search])
  useEffect(() => {
    if (pageState.page === 'catalog') setShow(true)
  }, [pageState.page])
  const handleClick = (i) => {
    //console.log('click',i)
    sendEvent(`Catalog Product ${i} clicked`, 'Catalog', 'Catalog')
    history.push(`/mv?id=${i}`)
  }
  let categories = content ? content : exampleCategories
  return (
    show && (
      <a.div
        style={{
          opacity,
          pointerEvents: pageState.page === 'catalog' ? 'auto' : 'none',
        }}
        className={css(styles.container)}
      >
        <SearchBar catalog />
        <Slide slideWidth={width} elementCount={productStore.products.length}>
          {catalogprods.map((item, i) => (
            <NewCard
              products
              onClick={() => handleClick(item.productCode)}
              img={thumbnailUrl + item.productCode}
              name={item.name}
            />
          ))}
        </Slide>
      </a.div>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#000000A4',
    zIndex: 50,
    overflow: 'hidden',
  },
  grey: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    top: 0,
    left: 0,
    background: colorThemes.bg[1],
    zIndex: 21,
    opacity: 0.8,
  },
  white: {
    position: 'absolute',
    width: '100%',
    height: '50%',
    top: '50%',
    left: 0,
    background: colorThemes.bg[0],
    zIndex: 21,
  },
})
