import { OrbitControls, PerspectiveCamera, useCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useControls } from "leva";
import { useSpring } from "@react-spring/core";
import React, { useEffect, useRef, useState } from "react";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import { cameraLocations, debugCamera } from "../../constants";
import { useCameraStore, useLocalLoader, usePage } from "../../stores";
import { CameraTween } from "./CameraTween";
import { FOVTween, Tweener, TweenManager } from "./Tweener";
import { TweenHelper } from "./TweenHelper";
import { animated as a } from "@react-spring/three";
import * as THREE from "three";
import TransformDebugger from "../TransformDebugger";
import ParallaxCamera from "./ParallaxCamera";
export const Camera = () => {
  const cameraState = useCameraStore();
  const { camera } = useThree();
  const controls = useRef();
  const pageState = usePage();

  const loader = useLocalLoader();
  const [isTweening, setIsTweening] = useState(false);
  const fovSpring = useSpring({ fov: cameraState.fov });
  // const {fov,x,y,z} = useControls({
  //     fov:{value:45,step:1},
  //     x:{value:0,step:0.55},
  //     y:{value:0,step:0.55},
  //     z:{value:-5,step:0.55},
  // })
  const { lookat, position, print_camera, disable, fov, ambientIntensity } =
    useControls({
      ambientIntensity: 0.5,
      fov: { value: 32, step: 0.1 },
      lookat: {
        value: { x: 0, y: 0, z: 0 },
        step: 0.02,
      },
      position: {
        value: { x: 0, y: 0, z: 0 },
        step: 0.06,
      },
      print_camera: { value: false },
      disable: { value: false },
    });
  // //console.log('rerender')
  // //console.log(controls)
  useEffect(() => {
    // prompt('',`position:new Vector3(${position.x},${position.y},${position.z}),lookAt:new Vector3(${lookat.x},${lookat.y},${lookat.z})`)
  }, [print_camera]);
  useEffect(() => {
    camera.position.set(Object.values(cameraLocations.cylinder.position));
    camera.updateProjectionMatrix();
  }, []);
  useFrame((state) => {
    // console.log(camera.position);
    controls.current.update();
    // camera.update()
  });
  return (
    <>
      <ambientLight intensity={0.5} />
      {debugCamera && (
        <>
          <PerspectiveCamera
            // position={disable?new THREE.Vector3(0,0,0):new THREE.Vector3(...Object.values(position))}
            // position={new THREE.Vector3(...Object.values(cameraLocations.cylinder.position))}
            position={new THREE.Vector3(...Object.values(position))}
            fov={fov}
            makeDefault
            // position={cameraLocations.cylinder.position}
            // fov={cameraLocations.cylinder.fov}
            // makeDefault={!disable}
          />
          <OrbitControls
            // rotateSpeed={[0,-1,0]}
            enableDamping={false}
            target={new THREE.Vector3(...Object.values(lookat))}
            // maxDistance={5.5} enabledPan={false} maxPolarAngle={Math.PI / 2}
            enabled={
              cameraState.enableControls ||
              cameraState.tweening ||
              pageState.page === "showcase"
            }
            enablePan={true}
            enableZoom={true}
            enableRotate={true}
            ref={controls}
            rotateSpeed={0}
          />
        </>
      )}
      {/* <fog attach="fog" args={['#202020', 20, 25]} /> */}
      {!debugCamera && (
        <>
          <OrbitControls
            // rotateSpeed={[0,-1,0]}
            enableDamping={false}
            enableRotate={false}
            enablePan={false}
            enableZoom={false}
            // target={new THREE.Vector3(...Object.values(lookat))}
            // maxDistance={5.5} enabledPan={false} maxPolarAngle={Math.PI / 2}
            enabled={
              cameraState.enableControls ||
              cameraState.tweening ||
              pageState.page === "showcase"
            }
            ref={controls}
            rotateSpeed={0}
          />
        </>
      )}
      <>
        {/* <Tweener  
                    to={cameraState.position} 
                    mover={camera.position}    
                    // from={{...camera.position}}
                    onStart={()=>setIsTweening(true)}
                    onEnd={()=>setIsTweening(false)}
                    // interpolation='lerp'
                    easing={TWEEN.Easing.Linear.None}
                    time={2}
                /> */}

        {/* {
                    controls.current && 
                    <Tweener
                        mover={controls.current.target}
                        // onStart={()=>setIsTweening(true)}
                        // onEnd={()=>setIsTweening(false)}
                        to={cameraState.lookAt}
                        time={2}
                    />
                } */}
        {/* <FOVTween from={camera.fov} to={cameraState.fov} time={2}/> */}
        <ParallaxCamera />
        <TweenManager controls={controls} />
        {debugCamera && (
          <>
            <TransformDebugger />
            <axesHelper />
          </>
        )}
      </>
    </>
  );
};
