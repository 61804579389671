import React, { useEffect, useRef, useState, Fragment } from "react";
import { css, StyleSheet } from "aphrodite";
import { baseUrl, useModal, thumbnailUrl } from "../cmsstore";
import { commonStyles } from "../commonStyles";
import axios from "axios";
import AR from "../../components/core/showcase/Ar";
import { CmsModelViewer } from "./CmsModelViewer";

export const Modal = (props) => {
  const modal = useModal();
  const [info, setInfo] = useState({
    name: "",
    tags: "",
    description: "",
    features: "",
    collectionName: "",
    category: "",
    finish: "",
    glb: "",
    usdz: "",
    thumbnail: "",
  });
  const fullscreenRef = useRef();
  const [view, setView] = useState({});
  const [actualGlb, setActualGlb] = useState();
  const [actualUsdz, setActualUsdz] = useState();
  const [loadingState, setLoading] = useState(0);
  const [actualImg, setImg] = useState();
  const [submit, setSubmit] = useState(true);
  const [viewThumbnail, setViewThumbnail] = useState(false);

  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value });
    setActualGlb(e.target.files[0]);
  };

  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value });
    setActualUsdz(e.target.files[0]);
  };

  const handleSubmitView = async () => {
    const formData = new FormData();
    formData.append("view", JSON.stringify(view));
    props.setLoading(1);

    try {
      let res = await axios.put(
        baseUrl + "/editView/" + modal.data._id,
        formData
      );
      props.setLoading(2);
    } catch (err) {
      props.setLoading(-1);
      console.log("err", err);
    }
    props.toggleChanged();
  };

  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value });
    setImg(e.target.files[0]);
  };

  const handleFullscreen = () => {
    fullscreenRef.current.requestFullscreen();
  };

  useEffect(() => {
    setInfo({
      features: "",
      collectionName: "",
      finish: "",
      tags: "",
      description: "",
      category: "",
      name: "",
      ...modal.data,
      glb: "",
      tags: modal.data.tags?.join(","),
      usdz: "",
      thumbnail: "",
      id: modal.data.productCode,
    });

    delete info.view;

    modal.data.view ? setView(modal.data.view) : setView({});
  }, [modal.data]);

  const handleModalClose = () => {
    setViewThumbnail(false);
    modal.setShow(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!window.confirm("Confirm to delete?")) {
      return;
    }

    try {
      let res = await axios.delete(
        baseUrl + `/?id=${modal.data._id}&modelId=${modal.data.productCode}`
      );

      handleModalClose();
      props.toggleChanged();
    } catch (err) {
      //console.log(err)
    }
    handleModalClose();
    props.toggleChanged();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("sending packet");

    props.setLoading(1);
    if (actualGlb) info["glb"] = actualGlb;
    else delete info.glb;
    if (actualUsdz) info.usdz = actualUsdz;
    else delete info.usdz;
    if (actualImg) info.thumbnail = actualImg;
    else delete info.thumbnail;
    let formData = new FormData();
    for (let i in info) {
      if (i === "tags") console.log(i, info[i], info[i].split(/[\s,]+/));
      if (i === "thumbnail") formData.append(i, actualImg);
      else if (i === "tags") formData.append(i, info[i].split(/[\s,]+/));
      else formData.append(i, info[i]);
    }

    info.glb = "";
    info.usdz = "";
    info.thumbnail = "";
    setLoading(1);

    try {
      let res = await axios.put(baseUrl + "/edit/" + modal.data._id, formData);
      props.setLoading(2);
    } catch (err) {
      props.setLoading(-1);
      console.log("err", err);
    }
    props.toggleChanged();
  };

  const handleThumbnail = (e) => {
    e.preventDefault();
    setViewThumbnail(true);
  };

  return (
    modal.show && (
      <Fragment>
        <div onClick={handleModalClose} className={css(styles.backdrop)}></div>
        <div className={css(styles.container)}>
          <div className={css(styles.header)}>
            <h3>Edit Product Details</h3>
            <div className={css(styles.close)} onClick={handleModalClose}>
              &#x2715;
            </div>
          </div>
          <div className={css(styles.product)}>
            <form className={css(styles.form)} onSubmit={handleSubmit}>
              <label className={css(commonStyles.label)}>Product Code</label>
              <div className={css(styles.productThumbnail)}>
                <div
                  className={css(styles.productCode)}
                  style={{ backgroundColor: "#eaeaea" }}
                >
                  {info.id}
                </div>

                <button onClick={handleThumbnail}>View Thumbnail</button>
              </div>
              <label className={css(commonStyles.label)}>
                Name <span className={css(commonStyles.required)}>*</span>
              </label>
              <input
                className={css(commonStyles.input)}
                name="name"
                placeholder="Name"
                value={info.name}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>
                Tags <span className={css(commonStyles.required)}>*</span>{" "}
                (comma separated)
              </label>
              <input
                className={css(commonStyles.input)}
                name="tags"
                placeholder="Tags"
                value={info.tags}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>Description</label>
              <textarea
                className={css(commonStyles.input)}
                name="description"
                placeholder="Description"
                value={info.description}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>
                Features ( Separate features using // )
              </label>
              <textarea
                className={css(commonStyles.input)}
                name="features"
                placeholder="Features"
                value={info.features}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>Collection Name</label>
              <input
                className={css(commonStyles.input)}
                name="collectionName"
                placeholder="Collection Name"
                value={info.collectionName}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>Category</label>
              <input
                className={css(commonStyles.input)}
                name="category"
                placeholder="Category"
                value={info.category}
                onChange={handleInfoChange}
              />
              <label className={css(commonStyles.label)}>Finish</label>
              <input
                className={css(commonStyles.input)}
                name="finish"
                placeholder="Finish"
                value={info.finish}
                onChange={handleInfoChange}
              />
              <div style={{ marginTop: "0.6em" }}>
                <label className={css(commonStyles.label, styles.labelBold)}>
                  Glb File <span className={css(commonStyles.required)}>*</span>
                </label>
                <input
                  type="file"
                  className={css(commonStyles.fileInput)}
                  value={info.glb}
                  onChange={handleGlbChange}
                />
              </div>
              <div>
                <label className={css(commonStyles.label, styles.labelBold)}>
                  Usdz File{" "}
                  <span className={css(commonStyles.required)}>*</span>
                </label>
                <input
                  type="file"
                  className={css(commonStyles.fileInput)}
                  value={info.usdz}
                  onChange={handleUsdzChange}
                />
              </div>
              <div>
                <label className={css(commonStyles.label, styles.labelBold)}>
                  Thumbnail{" "}
                  <span className={css(commonStyles.required)}>*</span>
                </label>
                <input
                  type="file"
                  className={css(commonStyles.fileInput)}
                  value={info.thumbnail}
                  onChange={handleImgChange}
                />
              </div>

              <div className={css(styles.actionBtns)}>
                <button
                  className={css(styles.deleteBtn)}
                  onClick={handleDelete}
                >
                  Delete Product
                </button>
                <button
                  disabled={!submit}
                  className={css(styles.submit)}
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </form>
            <div className={css(styles.model)}>
              <div className={css(styles.modelButtons, styles.setcamera)}>
                <button
                  className={css(styles.setcameraBtn)}
                  onClick={handleSubmitView}
                >
                  Set camera
                </button>
              </div>
              <div className={css(styles.relative)}>
                <AR
                  appleurl={
                    baseUrl + `/usdz/${modal.data.productCode}/model.usdz`
                  }
                  androidurl={
                    baseUrl + `/model/${modal.data.productCode}/model.glb`
                  }
                />

                <div className={css(styles.modelButtons, styles.fullscreen)}>
                  <button onClick={handleFullscreen}>Full Screen</button>
                </div>
              </div>
              <div style={{ height: "65vh" }} ref={fullscreenRef}>
                <CmsModelViewer
                  view={view}
                  setView={setView}
                  skyboxes={props.skyboxes}
                />
              </div>
            </div>
            {viewThumbnail && (
              <div className={css(styles.thumbnail)}>
                <div className={css(styles.thumbnailHeader)}>
                  <div
                    className={css(styles.close)}
                    onClick={() => setViewThumbnail(false)}
                  >
                    &#x2715;
                  </div>
                </div>
                <img
                  style={{
                    objectFit: "contain",
                    maxHeight: "400px",
                    maxWidth: "400px",
                    margin: "1em",
                  }}
                  src={thumbnailUrl + info.id}
                  alt={info.name}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  );
};

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    zIndex: 5,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  container: {
    position: "absolute",
    zIndex: 10,
    width: "90%",
    height: "78%",
    top: "50%",
    left: "50%",
    fontFamily: "Arial, Helvetica, sans-serif",
    backgroundColor: "white",
    borderRadius: "8px",
    transform: "translate(-50%,-50%)",
    "@media (max-width: 900px)": {
      width: "98%",
      left: "1%",
      transform: "translate(0,-50%)",
    },
    overflowY: "scroll",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1em",
  },
  close: {
    fontSize: "1.3em",
    cursor: "pointer",
    borderRadius: "50%",
    padding: "2px 5px",
    transition: "all 0.2s ease-in-out",

    ":hover": {
      backgroundColor: "#e5e5e5",
    },
  },
  productThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    position: "absolute",
    top: "18%",
    left: "40%",
    zIndex: "120",
    backgroundColor: "white",
    boxShadow: "0 4px 12px #cccccc",
    borderRadius: "8px",
  },
  thumbnailHeader: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "8px 8px 0 0",
  },
  productCode: {
    width: "75%",
    fontSize: "1em",
    outline: "none",
    border: "1.2px solid #b7b7b7",
    borderRadius: "4px",
    margin: "0.1em 0.2em 0.4em 0.2em",
    padding: "4px 6px",
  },
  product: {
    display: "flex",
    marginBottom: "1em",
  },
  form: {
    width: "40%",
    padding: "0 1em",

    "@media (max-width: 900px)": {
      display: "none",
    },
  },
  actionBtns: {
    display: "flex",
    justifyContent: "space-between",
  },
  submit: {
    backgroundColor: "#1976D2",
    color: "white",
    border: "none",
    fontSize: "0.9em",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "8em",
    boxShadow: "0 3px 8px #cccccc",

    ":disabled": {
      backgroundColor: "#e0e0e0",
      color: "#9b9b9b",
      cursor: "default",
    },
  },
  labelBold: {
    display: "block",
    fontWeight: "bold",
    fontSize: "0.8em",
  },
  model: {
    width: "55%",
    margin: "1em",

    "@media (max-width: 900px)": {
      width: "95%",
    },
  },
  relative: {
    position: "relative",
  },
  modelButtons: {
    position: "absolute",
    zIndex: 30,
  },
  delete: {
    right: "2%",
    bottom: "2%",
  },
  deleteBtn: {
    backgroundColor: "red",
    color: "white",
    border: "none",
    fontSize: "0.7em",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "0.6em",
  },
  setcamera: {
    bottom: "9%",
    right: "3%",
  },
  setcameraBtn: {
    backgroundColor: "orange",
    border: "none",
    fontSize: "0.8em",
    padding: "4px 8px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  fullscreen: {
    right: "0.2%",
  },
});
