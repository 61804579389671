import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { PMREMGenerator, UnsignedByteType } from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { SkyboxProducts } from '../../../constants'

export default function ProductSkybox() {
  const { gl, scene } = useThree()

  useEffect(() => {
    let pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath('skybox/')
      .load(SkyboxProducts, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture
        scene.environment = envMap
        texture.dispose()
        pmremGenerator.dispose()
      })

    return function cleanup() {
      pmremGenerator.dispose()
    }
  }, [gl, scene])

  return null
}
