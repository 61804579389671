import React, { useEffect, useRef, useState } from "react";
import DynamicSkybox from "./DynamicSkybox";
import { baseUrl, useModal } from "../cmsstore";
import {
  Html,
  OrbitControls,
  PerspectiveCamera,
  useProgress,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Suspense } from "react";
import { useControls } from "leva";
import { SkyboxProducts } from "../../constants";
import { ErrorBoundary } from "react-error-boundary";

const ModelViewer = ({ ...props }) => {
  const modal = useModal();
  const model = useRef();
  const ref = useRef();
  const { x, y, z, hdri, clampLeft_PI_by, clampRight_PI_by } = useControls({
    clampLeft_PI_by: {
      value: props.view && props.view.maxLeft ? props.view.maxLeft : 0,
      step: 1,
      min: -12,
      max: 12,
    },
    clampRight_PI_by: {
      value: props.view && props.view.maxRight ? props.view.maxRight : 0,
      step: 1,
      max: 12,
      min: -12,
    },
    // shouldClamp:{value:true,}
    hdri: {
      value: props.skyboxes[0],
      options: props.skyboxes,
    },
  });
  const { gl } = useThree();
  const { camera } = useThree();
  const controls = useRef();
  const orbitChanged = () => {
    props.setView((state) => ({
      ...state,
      position: camera.position,
      zoom: camera.zoom,
      lookAt: controls.current?.target,
    }));
  };
  useEffect(() => {
    if (!props.view || props.view == {} || !props.view.lookAt) return;
    controls.current.target.set(...Object.values(props.view.lookAt));
    camera.position.set(...Object.values(props.view.position));
    camera.zoom = props.view.zoom;
  }, []);
  useEffect(() => {
    props.setView((state) => ({
      ...state,
      maxLeft: clampLeft_PI_by,
      maxRight: clampRight_PI_by,
    }));
  }, [clampLeft_PI_by, clampRight_PI_by]);
  // useEffect(() => {
  //   props.setView({
  //     position:camera.position.clone(),
  //     zoom:camera.zoom,
  //     lookAt:controls.current?.target.clone()
  //   })
  // }, [camera,controls.current.target])
  model.current = useGLTF(baseUrl + `/model/${props.id}/model.glb`);
  // return null
  gl.setClearColor("grey");
  return (
    <>
      <DynamicSkybox hdri={hdri} />
      <OrbitControls
        onEnd={orbitChanged}
        minAzimuthAngle={Math.PI / clampLeft_PI_by}
        maxAzimuthAngle={Math.PI / clampRight_PI_by}
        enableDamping={false}
        // onChange={orbitChanged}
        ref={controls}
      />
      {model.current && (
        <primitive
          ref={ref}
          scale={40}
          // position={[x, y, z]}
          object={model.current.scene}
        />
      )}
    </>
  );
};
const Loader = () => {
  const { progress } = useProgress();
  return (
    <Html fullscreen>
      <h1>{progress.toFixed(3) + "%"}</h1>
    </Html>
  );
};
const Fallback = () => <p>bad glb</p>;
export const CmsModelViewer = (props) => {
  const modal = useModal();
  const controls = useRef();
  return (
    <>
      <ErrorBoundary FallbackComponent={() => <p>bad glb</p>}>
        <Canvas>
          <Suspense fallback={<Loader />}>
            {
              <ModelViewer
                controls={controls}
                view={props.view}
                setView={props.setView}
                skyboxes={props.skyboxes}
                id={modal.data.productCode}
              />
            }
          </Suspense>
        </Canvas>
      </ErrorBoundary>
    </>
  );
};
