import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useLocation } from 'react-router'
import { getParams } from '../../../Routing/RouteChecker'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { colorThemes } from '../../../../appStyles'
import { ModelViewer } from './components/ModelViewer'
import {
  getSimilarProducts,
  usePage,
  usePopup,
  useProducts,
} from '../../../../stores'
import { baseUrl, thumbnailUrl } from '../../../../cms/cmsstore'
import { ModelViewerContent } from './components/ModelViewerContent'
import { Content } from '../../HUD/popup/Content'
import AR from './../../../core/showcase/Ar'
import { PropertyBinding } from 'three'
import { SimilarSlide } from './components/SimilarSlide'
import { NewCard } from './components/NewCard'
import { useMeasure } from 'react-use'
import { useHistory } from 'react-router'
import { sendEvent } from '../../../../google-analytics'

export const ProductViewer = (props) => {
  const location = useLocation()
  const params = getParams(location.search)
  const pageState = usePage()
  const products = useProducts()
  const [show, setShow] = useState(false)
  const productState = useProducts()
  const [similarProducts, setSimilar] = useState([])
  const popup = usePopup()
  const [shouldShow, setShouldShow] = useState(true)
  const [selected, setSelected] = useState(false)
  const [ref, { width }] = useMeasure()
  const history = useHistory()
  let product = products.products.find((x) => x.productCode == params.id)
  const [suspend, setSuspend] = useState(false)

  const handleClick = (prodCode) => {
    sendEvent(
      `Product ${prodCode} viewer`,
      'Product Viewer',
      'Product Viewer Button'
    )
    history.push(`/mv?id=${prodCode}`)
    if (product) {
      setTimeout(() => {
        setSuspend(true)
      }, 2000)
    } else {
      setSuspend(false)
    }
  }

  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: pageState.page === 'mv' ? 1 : 0,
    onRest: () => pageState.page !== 'mv' && setShow(false),
  })
  useEffect(() => {
    if (pageState.page === 'mv') setShow(true)
  }, [pageState.page])

  useEffect(() => {
    setShouldShow(false)
    setTimeout(() => setShouldShow(true), [500])

    if (product != null && product.name) {
      // console.log('popoop' + product.productCode + 'id: ' + params.id)
      setSimilar(
        getSimilarProducts(
          product.tags,
          productState.products,
          product.productCode
        )
      )
    }
  }, [product])

  return (
    show && (
      <a.div
        style={{
          opacity,
          pointerEvents: pageState.page === 'mv' ? 'auto' : 'none',
        }}
        className={css(styles.container)}
      >
        <div className={css(styles.mid)}>
          <div className={css(styles.leftContainer)}>
            {params.id && (
              <>
                {console.log('model: ' + params.id)}
                <ModelViewer
                  url={baseUrl + `/model/${params.id}/model.glb`}
                  view={
                    products.products.find((x) => x.productCode == params.id)
                      ?.view || {}
                  } // important that we pass {} so it dont throw err
                  className={css(styles.canvas)}
                  shouldShow={shouldShow}
                />
                )
                <AR
                  className={css(styles.AR)}
                  appleUrl={baseUrl + `/usdz/${params.id}/model.usdz`}
                  androidURL={baseUrl + `/model/${params.id}/model.glb`}
                />
              </>
            )}
          </div>
          <div className={css(styles.rightContainer)} ref={ref}>
            {/* <ModelViewer className={css(styles.canvas)}/> */}
            <Content //REDUCE PADDING ON CONTENT IF CONTAINTER GET TOO LARGE
              model
              product={products.products.find(
                (x) => x.productCode == params.id
              )}
              {...products.products.find((x) => x.productCode == params.id)}
            />
            <div style={{ paddingLeft: '6vh', paddingBottom: '2vh' }}>
              {/* {console.log(
                'similar products: ' + similarProducts.collectionName
              )} */}
              <SimilarSlide slideWidth={width}>
                {similarProducts.slice(0, 20).map((prod, i) => {
                  return (
                    <>
                      {params.id && (
                        <NewCard
                          onClick={() => handleClick(prod.productCode)}
                          small
                          img={thumbnailUrl + prod.productCode}
                          key={i}
                          id={params.id}
                          productCode={prod.productCode}
                          selected={selected}
                          product={products.products.find(
                            (x) => x.productCode == params.id
                          )}
                          // {...products.products.find(
                          //   (x) => x.productCode == params.id
                          // )}
                          // name={prod.collectionName}
                        />
                      )}
                    </>
                  )
                })}
              </SimilarSlide>
            </div>
          </div>
        </div>
      </a.div>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(51, 51, 51, 0.95)',
    zIndex: 10,
  },
  mid: {
    position: 'absolute',
    // height: '64%',
    width: '100%',
    top: '50%',
    display: 'flex',
    transform: 'translateY(-50%)',
    left: 0,
    marginTop: '2vh',
    // backgroundColor: 'black',
    // padding:'4%'
  },
  canvas: {
    position: 'absolute',
    left: '0%',
    // top: '50%',
    margin: '0 0.9vw 0 0.9vw',
    borderRadius: '2vh',
    height: '100%',
    width: '50%',
    zIndex: 12,
    // transform: 'translateY(-50%)',
    backgroundColor: 'grey',
  },
  rightContainer: {
    // position: 'absolute',
    // right: '0%',
    top: 0,
    width: '50%',
    height: '100%',
    // backgroundColor:'yellow'
  },
  leftContainer: {
    // position
    width: '50%',
  },
  AR: {
    position: 'absolute',
    right: '49.5%',
    bottom: '2%',
    zIndex: 13,
    cursor: 'pointer',
  },
})
