import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { slideInLeft, slideOutLeft } from 'react-animations'
import { useSpring } from '@react-spring/core'
import { useCustomLoader, useLocalLoader } from '../../stores'
import { Html, useProgress } from '@react-three/drei'
import { animated as a } from '@react-spring/web'
import LoadingBar from './LoadingBar'
import { colorThemes } from '../../appStyles'
export const LoadingScreen = (props) => {
  // const useLoading
  // const {progress} = useProgress()
  const localLoader = useLocalLoader()
  const { isDone, reportLoaderDone } = useCustomLoader()
  const firstTime = useRef(true)
  // const {transform} = useSpring( {from:{transform:'translateX(0%)'},transform:localLoader.progress<100?'translateX(0%)':'translateX(-100%)',delay:localLoader.progress<100?0:firstTime.current?0:0})
  const { opacity } = useSpring({
    from: { opacity: 1 },
    opacity: localLoader.progress < 100 || !isDone ? 1 : 0,
    delay:
      localLoader.progress < 100 || !isDone ? 0 : firstTime.current ? 200 : 0,
  })

  const [customLoader, setCustomLoader] = useState(false)

  useEffect(() => {
  }, [isDone])
  return (
    <>
      <a.div
        style={{ opacity }}
        className={css(
          styles.container
          // localLoader.progress<100?styles.slidein:styles.slideout
        )}
      >
        {/* <LoadingBar amount={localLoader.progress.toFixed(0)} /> */}
        <div className={css(styles.loadingContainer)}>
          <div className={css(styles.loadingTitle)}>Loading</div>
          <div className={css(styles.loadingSubtitle)}>
            This will only take a few moments
          </div>
        </div>
        {/* css loader thing  */}
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </a.div>
    </>
  )
}
const slide = {
  from: {
    transform: 'translateX(-100%)',
  },
  to: {
    transform: 'translateX(0)',
  },
}
const styles = StyleSheet.create({
  loadingContainer: {
    textAlign: 'center',
    paddingTop: '15vh',
  },
  loadingTitle: {
    paddingTop: '8vh',
    fontSize: 36,
    opacity: 0.9,
    color: colorThemes.text[0],
  },
  loadingSubtitle: {
    paddingTop: '2vh',
    fontSize: 18,
    opacity: 0.7,
    color: colorThemes.text[0],
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity:0,
    // transform:'translateX(-100%)',
    backgroundColor: 'black',
    pointerEvents: 'none',
    // opacity:0.5,
    zIndex: 1000,
    // animationName:slideInLeft,
  },
  logo: {
    height: '15vh',
    width: 'auto',
    zIndex: 1000,
  },
  perc: {
    // marginTop:'50%',
    // marginLeft:'50%',
    position: 'absolute',
    left: '48%',
    top: '48%',
    // color:'#fafade',
    fontFamily: 'Helvetica',
  },
  slidein: {
    animationName: slide,
    animationDuration: '1s',
    // animationFillMode:'forwards',
    // res
  },
  slideout: {
    animationName: slide,
    animationDirection: 'reverse',
    animationDuration: '1s',
    // animationFillMode:'forwards'
  },
  none: {
    pointerEvents: 'none',
  },
})
