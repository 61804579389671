import React, { useState, useEffect } from "react";
import { css, StyleSheet } from "aphrodite";
import { commonStyles } from "../commonStyles";
import axios from "axios";
import { baseUrl } from "../cmsstore";

export const Create = (props) => {
  const [info, setInfo] = useState({
    productCode: "",
    name: "",
    tags: "",
    description: "",
    features: "",
    collectionName: "",
    category: "",
    finish: "",
    glb: "",
    usdz: "",
    thumbnail: "",
  });
  const [actualGlb, setActualGlb] = useState({});
  const [actualUsdz, setActualUsdz] = useState({});
  const [submit, setSubmit] = useState(false);
  const [skyboxInfo, setSkyboxInfo] = useState({});
  const [actualHdr, setActualHdr] = useState();
  const [actualImg, setImg] = useState();

  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value });
    setActualGlb(e.target.files[0]);
  };

  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value });
    setActualUsdz(e.target.files[0]);
  };

  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value });
    setImg(e.target.files[0]);
  };

  useEffect(() => {
    if (
      info.glb &&
      info.usdz &&
      info.name &&
      info.productCode &&
      info.tags &&
      !props.data.some((item) => item.productCode === info.productCode)
    ) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [info]);

  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    props.setLoading(1);
    setSubmit(true);
    info["glb"] = actualGlb;
    info.usdz = actualUsdz;

    let formData = new FormData();
    for (let i in info) {
      if (i === "thumbnail") formData.append(i, actualImg);
      else if (i === "tags") formData.append(i, info[i].split(/[\s,]+/));
      else formData.append(i, info[i]);
    }
    info["glb"] = "";
    info.usdz = "";
    info.thumbnail = "";

    try {
      let res = await axios.post(baseUrl, formData);
      props.setLoading(2);
      setInfo({
        ...info,
        productCode: "",
        name: "",
        tags: "",
        description: "",
        features: "",
        collectionName: "",
        category: "",
        finish: "",
      });
    } catch (err) {
      console.log(err);
      props.setLoading(-1);
    }

    props.toggleChanged();
  };

  const handleSkyboxSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("name", skyboxInfo.name);
    formData.append("hdr", actualHdr);
    try {
      props.setLoading(1);
      await axios.post(baseUrl + "/skybox", formData);
      props.setLoading(2);
    } catch (err) {
      props.setLoading(-1);
      //console.log(err)
    }

    props.toggleChanged();
  };

  const handleSkyboxChange = (e) => {
    setActualHdr(e.target.files[0]);
    setSkyboxInfo({ ...skyboxInfo, hdr: e.target.value });
  };

  return (
    <div className={css(styles.container)}>
      <div>
        <h2 className={css(styles.heading)}>Create Product</h2>
        <form className={css(styles.form)} onSubmit={handleSubmit}>
          <label className={css(commonStyles.label)}>
            Product Code <span className={css(commonStyles.required)}>*</span>
          </label>
          <input
            className={css(commonStyles.input)}
            name="productCode"
            placeholder="Product Code"
            value={info.productCode}
            onChange={handleInfoChange}
          />
          <p className={css(styles.error)}>
            {props.data.some((item) => item.productCode === info.productCode) &&
              "Duplicate productCode"}
          </p>
          <label className={css(commonStyles.label)}>
            Name <span className={css(commonStyles.required)}>*</span>
          </label>
          <input
            className={css(commonStyles.input)}
            name="name"
            placeholder="Name"
            value={info.name}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>
            Tags <span className={css(commonStyles.required)}>*</span> (comma
            separated)
          </label>
          <input
            className={css(commonStyles.input)}
            name="tags"
            placeholder="Tags"
            value={info.tags}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>Description</label>
          <textarea
            className={css(commonStyles.input)}
            name="description"
            placeholder="Description"
            value={info.description}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>
            Features ( Separate features using // )
          </label>
          <textarea
            className={css(commonStyles.input)}
            name="features"
            placeholder="Features"
            value={info.features}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>Collection Name</label>
          <input
            className={css(commonStyles.input)}
            name="collectionName"
            placeholder="Collection Name"
            value={info.collectionName}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>Category</label>
          <input
            className={css(commonStyles.input)}
            name="category"
            placeholder="Category"
            value={info.category}
            onChange={handleInfoChange}
          />
          <label className={css(commonStyles.label)}>Finish</label>
          <input
            className={css(commonStyles.input)}
            name="finish"
            placeholder="Finish"
            value={info.finish}
            onChange={handleInfoChange}
          />
          <div style={{ marginTop: "1em" }}>
            <label className={css(commonStyles.label, commonStyles.labelBold)}>
              Glb File <span className={css(commonStyles.required)}>*</span>
            </label>
            <input
              type="file"
              className={css(commonStyles.fileInput)}
              value={info.glb}
              onChange={handleGlbChange}
            />
          </div>
          <div>
            <label className={css(commonStyles.label, commonStyles.labelBold)}>
              Usdz File <span className={css(commonStyles.required)}>*</span>
            </label>
            <input
              type="file"
              className={css(commonStyles.fileInput)}
              value={info.usdz}
              onChange={handleUsdzChange}
            />
          </div>
          <div>
            <label className={css(commonStyles.label, commonStyles.labelBold)}>
              Thumbnail <span className={css(commonStyles.required)}>*</span>
            </label>
            <input
              type="file"
              className={css(commonStyles.fileInput)}
              value={info.thumbnail}
              onChange={handleImgChange}
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <button
              disabled={!submit}
              className={css(styles.submit)}
              type="submit"
            >
              Create
            </button>
          </div>
        </form>
      </div>
      <div className={css(styles.skybox)}>
        <h2 className={css(styles.heading)}>Add Skybox</h2>
        <form className={css(styles.form)} onSubmit={handleSkyboxSubmit}>
          <label className={css(commonStyles.label)}>
            Name <span className={css(commonStyles.required)}>*</span>
          </label>
          <input
            className={css(commonStyles.input)}
            name="name"
            placeholder="Name"
            value={skyboxInfo.name}
            onChange={(e) =>
              setSkyboxInfo({ ...skyboxInfo, name: e.target.value })
            }
          />
          <p className={css(styles.error)}>
            {props.skyboxes.some((x) => x === skyboxInfo.name) && (
              <span style={{ color: "red" }}>Duplicate Name</span>
            )}
          </p>
          <div style={{ marginTop: "0.2em" }}>
            <label className={css(commonStyles.label)}>
              HDR File <span className={css(commonStyles.required)}>*</span>
            </label>
            <input
              type="file"
              className={css(commonStyles.fileInput)}
              value={skyboxInfo.hdr}
              onChange={handleSkyboxChange}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              disabled={
                !(
                  skyboxInfo.name &&
                  skyboxInfo.hdr &&
                  !props.skyboxes.some((x) => x === skyboxInfo.name)
                )
              }
              className={css(styles.submit)}
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    fontFamily: "Arial, Helvetica, sans-serif",
    marginTop: "1.2em",
    height: "84vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  heading: {
    margin: 0,
  },
  skybox: {
    marginTop: "1.5em",
  },
  form: {
    margin: "0.6em 2em",
    width: "85%",
  },
  error: {
    color: "red",
    margin: 0,
    fontSize: "0.7em",
  },
  submit: {
    backgroundColor: "#1976D2",
    color: "white",
    border: "none",
    fontSize: "1em",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "8em",
    boxShadow: "0 3px 8px #cccccc",

    ":disabled": {
      backgroundColor: "#e0e0e0",
      color: "#9b9b9b",
      cursor: "default",
    },
  },
});
