import { useGLTF } from '@react-three/drei'
import React from 'react'

export const BackDrop = () => {
  const glb = useGLTF(
    './environment/Fin_HomeScreen.glb',
    'https://www.gstatic.com/draco/versioned/decoders/1.5.0/'
  )
  return (
    <group rotation={[0, Math.PI / 9.8, 0]}>
      <primitive object={glb.scene} />
    </group>
  )
}
