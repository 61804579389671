import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { usePage } from '../../../../stores'
import { pageIdx } from '../../../../constants'
import { useHistory } from 'react-router'
import { Logo } from '../../../assets/svgs/Logo'
import {
  colorThemes,
  textStyles,
  generalStyles,
  textStylesV2,
  buttonStylesV2,
} from '../../../../appStyles'
import { NavIcon } from '../../../assets/svgs/NavIcon'
import { slideInLeft } from 'react-animations'
import { sendEvent } from '../../../../google-analytics'

export const Intro = (props) => {
  const pageState = usePage()
  const { opacity } = useSpring({ opacity: pageState.page === '' ? 1 : 0 })
  const history = useHistory()

  const handleClick = () => {
    history.push('/cylinder?room=1')
    sendEvent(
      `Start Experience clicked`,
      'Landing Page',
      'Start Experience Button'
    )
  }

  return (
    <a.div
      style={{
        pointerEvents: pageState.page === '' ? 'auto' : 'none',
        opacity,
      }}
      className={css(styles.container)}
    >
      {/* <Logo className={css(generalStyles.logo)} />
            <div className={css(generalStyles.line)}></div>
            <div className={css(generalStyles.line,styles.bottomLine)}></div> */}
      {/* <div className={css(styles.NavBar)}>
        <span className={css(styles.navSpan)}>
          <NavIcon className={css(styles.icon)} />
          <p className={css(textStyles.nav20, styles.navText)}>Design</p>
        </span>
        <span className={css(styles.navSpan)}>
          <NavIcon className={css(styles.icon)} />
          <p className={css(textStyles.nav20, styles.navText)}>Technology</p>
        </span>
        <span className={css(styles.navSpan)}>
          <NavIcon className={css(styles.icon)} />
          <p className={css(textStyles.nav20, styles.navText)}>Future</p>
        </span>
      </div> */}
      <div className={css(styles.textCtr)}>
        <div
          className={css(
            textStylesV2.LabelL,
            textStylesV2.GodrejGreen,
            styles.label
          )}
        >
          {'Welcome to the'.toUpperCase()}
        </div>
        <div
          className={css(
            textStylesV2.HeadlineL,
            textStylesV2.BasicColor,
            styles.header
          )}
        >
          Godrej Locks &amp; Architectural Fittings and Systems' Virtual
          Experience{' '}
        </div>
        <button
          onClick={handleClick}
          className={css(
            buttonStylesV2.basicCTA,
            styles.CTA
            // buttonStylesV2.GodrejGreen,
          )}
          style={{
            pointerEvents: pageState.page === '' ? 'auto' : 'none',
            cursor: 'pointer',
          }}
        >
          Start Experience
        </button>
      </div>
      {/* <button>START</button> */}
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: pageIdx.fullScreen,
    // backgroundColor:'white'
  },
  label: {
    marginTop: 0,
  },
  header: {
    // marginTop: 32,
    marginTop: '2vh',
  },
  CTA: {
    marginTop: '4vh',
    lineHeight: '7vh',
    backgroundColor: colorThemes.textBlue,
  },
  smallHeading: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '31.2px',
    letterSpacing: '11%',
    color: '#7BB348',
    pointerEvents: 'none',
    animationName: [slideInLeft],
    animationDuration: '1s',
  },
  textCtr: {
    position: 'absolute',
    top: '30vh',
    left: '4vw',
    width: '35vw',
  },
  heading: {
    fontFamily: 'Arial',
    fontSize: '84px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '92px',
    letterSpacing: '-0.045em',
    textAlign: 'left',
    pointerEvents: 'none',
    color: colorThemes.text[0],
  },
  button: {
    backgroundColor: colorThemes.button[0],
    borderRadius: '10px',
  },
  bottomLine: {
    top: 'calc(100% - 2.4%)',
  },
  NavBar: {
    // backgroundColor:'red',
    position: 'absolute',
    left: '54%',
    // paddingLeft:'2%',
    transform: 'translate(-50%,50%)',
    width: '70%',
    height: '8.6%',
    bottom: '9%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  icon: {
    width: '4%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  navSpan: {
    width: '25.3%',
    height: '100%',
    // backgroundColor:'yellow',
    display: 'flex',
  },
  navText: {
    // marginLeft:'10%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0,-50%)',
    margin: 0,
    marginLeft: '5%',
    cursor: 'pointer',

    // left:'4%'
    // marginTop:'20.4%',
    // transform:'translateY(-50%)'
  },
  abs: {
    position: 'absolute',
  },
})
