import React, { useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { ItemsContainer } from "./components/ItemsContainer";
import { Modal } from "./components/Modal";
import { Create } from "./components/Create";
import axios from "axios";
import { baseUrl } from "./cmsstore";
import { CmsLoading } from "./components/CmsLoading";
import { QRModal } from "../components/core/QRModal";
import { useQR } from "../stores";
import AuthUserUI from "./components/AuthUserUI";
import { searchKeys } from "../constants";

const filteredData = ({ key, value }, data) => {
  if (!key) {
    return data;
  } else {
    return data.filter((item) =>
      item.hasOwnProperty(key)
        ? item[key].toLowerCase().slice(0, value.length) === value.toLowerCase()
          ? true
          : false
        : false
    );
  }
};
export const Cms = (props) => {
  const [data, setData] = useState([]);
  const [changed, setChanged] = useState(true);
  const [loading, setLoading] = useState(0);
  const qr = useQR();
  const [skyboxes, setSkyboxes] = useState([]);
  const [search, setSearch] = useState({ key: "name", value: "" });
  const [validated, setValidated] = useState(false);

  const toggleChanged = () => {
    setChanged((state) => !state);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        let response = await axios.get(baseUrl);
        response.data = response.data
          .map((x) => ({
            ...x,
            updatedAt: x.updatedAt.replace(/T/, " ").replace(/\..+/, ""),
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchHdri = async () => {
      try {
        let response = await axios.get(baseUrl + "/skybox");
        setSkyboxes(response.data.map((x) => x.name));
      } catch (err) {}
    };

    fetch();
    fetchHdri();
  }, [changed]);

  return validated ? (
    <div className={css(styles.cms)}>
      <Modal
        skyboxes={skyboxes}
        setLoading={setLoading}
        toggleChanged={toggleChanged}
        data={data}
      />
      {qr.url && <QRModal />}

      <CmsLoading loading={loading} />

      <div className={css(styles.container)}>
        <div className={css(styles.nav)}>
          <h1 className={css(styles.heading)}>Godrej LAFS</h1>
          <button
            className={css(styles.logout)}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </button>
        </div>
        <div className={css(styles.main)}>
          <div className={css(styles.products)}>
            <div className={css(styles.search)}>
              <span className={css(styles.searchElement)}>
                <select
                  name="key"
                  value={search.key}
                  onChange={(e) =>
                    setSearch((state) => ({ ...state, key: e.target.value }))
                  }
                  className={css(styles.searchSelect)}
                >
                  {searchKeys.map((s) => (
                    <option key={s.key} value={s.key}>
                      {s.name}
                    </option>
                  ))}
                </select>
                <input
                  name="value"
                  value={search.value}
                  className={css(styles.searchInput)}
                  onChange={(e) =>
                    setSearch((state) => ({ ...state, value: e.target.value }))
                  }
                  placeholder="Search"
                />
              </span>
            </div>
            <div className={css(styles.items)}>
              <ItemsContainer data={filteredData(search, data)} />
            </div>
          </div>
          <div className={css(styles.create)}>
            <Create
              skyboxes={skyboxes}
              setLoading={setLoading}
              toggleChanged={toggleChanged}
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <AuthUserUI setValidated={setValidated} />
  );
};

const styles = StyleSheet.create({
  cms: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
  },
  nav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.8em 1.2em",
    boxShadow: "0 4px 12px #cccccc",
  },
  heading: {
    margin: 0,
  },
  main: {
    display: "flex",
  },
  products: {
    width: "62%",
    padding: "1.5em 2em",
    "@media (max-width: 900px)": {
      width: "55%",
    },
  },
  search: {
    textAlign: "end",
  },
  searchElement: {
    border: "2px solid #cecece",
    overflow: "auto",
    whiteSpace: "nowrap",
    padding: "0.3em 0",
    borderRadius: "1em",
    overflow: "hidden",
  },
  searchSelect: {
    border: "none",
    outline: "none",
    padding: "0.3em",
    borderRadius: "1em 0 0 1em",
    fontSize: "1em",
  },
  searchInput: {
    border: "none",
    outline: "none",
    padding: "0.3em",
    borderRadius: "0 1em 1em 0",
    fontSize: "1em",
  },
  items: {
    margin: "1em 0",
    maxHeight: "80vh",
    overflow: "auto",

    "::-webkit-scrollbar": {
      width: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: "#dbdbdb",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
  },
  create: {
    width: "32%",
    "@media (max-width: 900px)": {
      width: "30%",
    },
  },
  logout: {
    backgroundColor: "#fc5353",
    color: "white",
    border: "none",
    fontSize: "1em",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
    boxShadow: "0 3px 8px #cccccc",
  },
});
