import axios from "axios";
import { Vector3 } from "three";
import create from "zustand";
import { cameraLocations } from "./constants";
import { baseUrl } from "./cms/cmsstore";
import { StandardNodeMaterial } from "three-stdlib";

export const useCameraStore = create((set) => ({
  enableControls: false,
  position: cameraLocations.cylinder.position,
  lookAt: new Vector3(0, 0, 0),
  fov: 21,
  tweening: false,
  time: 2000,
  setCamera: (position, lookAt, fov, time, enableControls) =>
    set((state) => {
      state.position = position;
      state.lookAt = lookAt;
      state.fov = fov;
      state.time = time;
      state.enableControls = enableControls ? true : false;
      // state.tweening = true
    }),
  setTweening: (bol) => set((state) => (state.tweening = bol)),
}));

export const useCustomFade = create((set) => ({
  status: false,
  setStatus: (state) => set({ status: state }),
}));

export const useCylinder = create((set) => ({
  room: 0,
  location: 0,
  popup: -1,
  info: {},
  enableWobble: true,
  enableRotate: false,
  setWobble: (bol) => set((state) => (state.enableWobble = bol)),
  setRotate: (bol) => set((state) => (state.enableRotate = bol)),
  setInfo: (info) => set((state) => (state.info = info)),
  setRoom: (room) =>
    set((state) => {
      state.room = room;
      state.popup = -1;
      state.location = 0;
    }),
  setLocation: (loc) => set((state) => (state.location = loc)),
  setPopup: (popup) => set((state) => (state.popup = popup)),
}));

export const usePage = create((set) => ({
  page: "",
  lastPage: "",
  setPage: (page) =>
    set((state) => {
      state.lastPage = state.page;
      state.page = page;
    }),
}));
// const use

export const useFullscreen = create((set) => ({
  show: false,
  page: "",
  setPage: (page) => set((state) => (state.page = page)),
  clear: () => set((state) => (state.page = "")),
}));

export const useHUD = create((set) => ({
  show: false,
  page: "",
  setPage: (page) => set((state) => (state.page = page)),
  clear: () => set((state) => (state.page = "")),
}));

export const useWorkaround = create((set) => ({
  url: "",
  currentSetUrl: "",
  popup: "",
  type: "",
  setType:(value) =>set((state)=> (state.type = value)), //0 = Hotspot ; 1 = Spawnpoint
  setUrl: (url) => set((state) => (state.url = url)),
  setPopup: (idx) => set((state) => (state.popup = idx)),
  setCurrentUrl: (url) => set((state) => (state.currentSetUrl = url)),
}));
export const useQR = create((set) => ({
  url: "",
  setQR: (url) => set((state) => (state.url = url)),
}));
export const useRoom = create((set) => ({
  url: "",
  lastUrl: "",
  data: {},
  setUrl: (url) =>
    set((state) => {
      state.url = url;
      // state.
    }),
  setData: (data) => set((state) => (state.data = data)),
}));
export const useShouldMount = create((set) => ({
  cylinder: true,
  showcase: false,
  setCylinder: (bol) => set((state) => (state.cylinder = bol)),
  setShowcase: (bol) => set((state) => (state.showcase = bol)),
}));
export const useCustomLoader = create((set, get) => ({
  trackedItems: ["skybox"],
  doneItems: [],
  isDone: false,
  reportLoaderDone: (state) => {
    if (get().isDone) return;
    let done = get().doneItems;
    if (!done.includes(state)) {
      let total = get().trackedItems;
      if (total.includes(state)) {
        done.push(state);
        if (done.length == total.length) {
          set({ isDone: true });
        }
      }
    }
  },
}));

export const useParallax = create((set) => ({
  canParallax: false,
  shouldMove: false,
  setParallax: (bol) => set({ isTweening: bol }),
  setMove: (bol) => set((state) => (state.shouldMove = bol)),
}));
export const useNewLoadingScreen = create((set) => ({
  isLoading: true,
  isSkyboxLoading: true,
  progress: 0,
  setIsSkyboxLoading: (bol) => set((state) => (state.isSkyboxLoading = bol)),
  setIsLoading: (bol) => set((state) => (state.isLoading = bol)),
  setProgress: (prog) => set((state) => (state.progress = prog)),
}));
export const useLocalLoader = create((set) => ({
  isLoading: false,
  progress: 0,
  cache: [],
  setLoading: (bol) => set((state) => (state.isLoading = bol)),
  setProgress: (prog) => set((state) => (state.progress = prog)),
  appendToCache: (url) => set((state) => (state.cache = [...state.cache, url])),
}));

export const usePopup = create((set) => ({
  data: {},
  setData: (data) => set((state) => (state.data = data)),
}));

export const useProducts = create((set) => ({
  products: [],
  fetch: async () => {
    const response = await axios.get(baseUrl);
    set({
      products: response.data.map((x) => ({ ...x })),
    });
  },
  setProducts: (prods) => set((state) => (state.products = prods)),
}));

export const useSearch = create((set) => ({
  category: 0,
  search: "",
  setCategory: (cat) => set((state) => (state.category = cat)),
  setSearch: (search) => set((state) => (state.search = search)),
}));
/*
Uncomment later
*/
export function getSimilarProducts(tags, products, productCode) {
  let res = [];
  for (let product in products) {
    let count = 0;
    for (let tag in tags) {
      if (products[product].tags.includes(tags[tag])) {
        count += 1;
      }
    }
    if (products[product].productCode === productCode) count = 50; // so that main prod shows first
    // change later
    if (count > 0 && products[product].productCode !== productCode)
      if (count > 0 || products[product].productCode === productCode) {
        res = [...res, { ...products[product], similarity: count }];
      }
  }
  res.sort((a, b) => b.similarity - a.similarity);
  // console.log('res: ' + res)
  return res;
}
