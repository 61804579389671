import React from 'react'
import useReflector from '../../Misc/reflector/shaders/useReflector'
import usePostprocessing from '../../Misc/reflector/shaders/usePostprocessing'
import { Circle } from '@react-three/drei'
import './../../Misc/reflector/shaders/materials/ReflectorMaterial'
import { Reflector as R } from 'three-stdlib'
import './../../Misc/reflector/shaders/materials/ScreenMaterial'
import { extend, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useState } from 'react'
import { useControls } from 'leva'
extend({ R })
export const Reflector = () => {
  const [meshRef, reflectorProps, passes] = useReflector()
  const { camera } = useThree()
  const { y, reflector } = useControls({
    y: { value: -3.16, step: 0.01 },
    reflector: { value: 100, step: 0.05 },
  })
  const [virtualScene] = useState(() => new THREE.Scene())
  const screenProps = usePostprocessing(virtualScene, camera, passes)

  return (
    <Circle
      ref={meshRef}
      args={[reflector, 36, 36]}
      rotation-x={-Math.PI / 2}
      position={[1, y, 0]}
    >
      <reflectorMaterial
        transparent
        opacity={0.3}
        color="black"
        metalness={1}
        roughness={1}
        {...reflectorProps}
      />
    </Circle>
  )
  return null
  //     <r>
  //     <Circle ref={meshRef} args={[34.75, 36, 36]} rotation-x={-Math.PI / 2} position={[1, 0.39, 0]}>
  // {/* //     <reflectorMaterial transparent opacity={0.5} color="black" metalness={0.95} roughness={1} {...reflectorProps} /> */}

  //  </Circle>

  //     </r>
}
