import { useSpring } from '@react-spring/core'
import React, { useEffect, useState } from 'react'
import {
  getSimilarProducts,
  usePage,
  usePopup,
  useProducts,
  useWorkaround,
} from '../../../../stores'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'
import { colorThemes } from '../../../../appStyles'
import { Content } from './Content'
import { Catalog } from '../../fullscreen/pages/Catalog'
import { SimilarProducts } from './SimilarProducts'
import { SimilarSlide } from '../../fullscreen/pages/components/SimilarSlide'
import { NewCard } from '../../fullscreen/pages/components/NewCard'
import { useMeasure } from 'react-use'
import { thumbnailUrl } from '../../../../cms/cmsstore'
import { useHistory } from 'react-router'
import { isMobile } from 'react-device-detect'

export const PopupScreen = ({ mount, unmount }) => {
  const handleClick = (prodCode) => {
    history.push(`/mv?id=${prodCode}`)
  }
  const popup = usePopup()
  const history = useHistory()
  const pageState = usePage()
  const [data, setData] = useState({})
  const productState = useProducts()
  const [ref, { width }] = useMeasure()
  const [shouldShow, setShouldShow] = useState(true)
  const [similarProducts, setSimilar] = useState([])
  const { transform } = useSpring({
    transform:
      popup.data?.productCode && pageState.page === 'showcase' && shouldShow
        ? 'translate(0%,0%)'
        : 'translate(130%,-0%)',
    // onRest:()=>!(popup.data?.productCode && pageState.page === 'showcase' && shouldShow) && unmount()
    // delay: popup.data?.productCode && pageState.page === 'showcase' ? 2000 : 0,
  })

  useEffect(() => {
    //console.log('popoop',popup.data)
    setShouldShow(false)
    setTimeout(
      () => setShouldShow(true),
      [pageState.lastPage === 'mv' ? 0 : 2000]
    )
    // console.log('popup data: ' + popup)
    if (popup.data?.name) {
      setData(popup.data)
      setSimilar(
        getSimilarProducts(
          popup.data.tags,
          productState.products,
          popup.data.productCode
        )
      )
    }
  }, [popup.data])
  const workaround = useWorkaround()
  //   //console.log("popup", data);
  const handleClose = () => {
    workaround.setPopup(-1)
  }
  return (
    <a.div
      ref={ref}
      style={{
        pointerEvents: data.productCode ? 'auto' : 'none',
        transform,
      }}
      className={css(styles.container)}
    >
      {/* <div style={{ height: '70%' }}> */}
      <Content
        similarProducts={similarProducts}
        handleClose={handleClose}
        {...data}
      />
      {/* </div> */}
      {/* <div className={css(styles.scroll)}>
        {similarProducts && (
          <SimilarProducts products={similarProducts.slice(0, 20)} />
        )}
      </div> */}
      <div style={{ marginTop: '2vh' }}>
        <SimilarSlide slideWidth={width}>
          {similarProducts.slice(0, 20).map((prod, i) => {
            return (
              <NewCard
                onClick={() => handleClick(prod.productCode)}
                small
                img={thumbnailUrl + prod.productCode}
                key={i}
              />
            )
          })}
        </SimilarSlide>
      </div>
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '34.5vw',
    // backgroundColor:'red',
    height: isMobile ? '65vh' : '80vh',
    boxSizing: 'border-box',
    // transform: "translate(-50%,-50%)",
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 100,
    top: isMobile ? '10vh' : '14vh',
    right: '5%',
  },
  scroll: {
    marginTop: '4.5vh',
    height: '34%',
    width: '100%',
    // backgroundColor:'red',
    overflowY: 'visible',
    // boxShadow: '10px 10px 50px 3px #275C8D1A',
    opacity: 0.9,
    borderRadius: '10px',
    // backgroundColor: colorThemes.popup[0],
    // padding:'0.8vw 0.5vw 0.5vw 1.2vw'
  },
})
