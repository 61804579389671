import { colorToRgba } from '@react-spring/shared'
import { css, StyleSheet } from 'aphrodite'
import { useEffect } from 'react'
import { colorThemes, textStylesV2 } from '../../../../../appStyles'

export const NewCard = (props) => {
  const styles = StyleSheet.create({
    container: {
      textAlign: 'center',
      marginRight: '2vh',
      marginLeft: '0.4vh',
      backgroundColor: colorThemes.card[0],
      // backgroundColor: 'red',
      // height:props.small?'80%':'100%',
      height: props.small ? '15vh' : '100%',
      minWidth: props.small ? '8vh' : '42vh',
      padding: '1.5vh',
      boxSizing: 'border-box',
      borderRadius: props.small ? '5%' : '2%',
      overflow: 'hidden',
    },
    imageContainer: {
      height: props.small ? '100%' : '80%',
      cursor: 'pointer',
    },
    img: {
      borderRadius: '3%',
      pointerEvents: 'none',
    },
    p: {
      fontSize: props.category ? '3vh' : '2.4vh',
      fontFamily: 'Work Sans',
      lineHeight: props.category ? '3.4vh' : '2.6vh',
      margin: 0,
      marginTop: props.category ? '3vh' : '2.6vh',
      position: 'relative',
      // width: '20vw'
    },
  })
  return (
    <div {...props} onClick={() => {}} className={css(styles.container)}>
      <div
        onClick={props.onClick}
        className={css(styles.imageContainer)}
        // style={{
        //   display: props.productCode === props.id ? 'block' : 'none',
        // }}
      >
        <img className={css(styles.img)} height="100%" src={props.img} />
        <p className={css(styles.p)}>{props.name}</p>
      </div>
    </div>
  )
}
