import React from "react";

function Direction(props) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.825 5.47091C-0.16246 13.4334 4.16012e-05 12.8917 4.16012e-05 13.5959C4.16012e-05 13.8126 0.162542 14.0292 0.325042 14.0834L8.72087 17.2792C12.025 25.6209 11.7 26.0001 12.4042 26.0001C12.4584 26.0001 12.7834 26.0001 12.8917 25.6751L20.5292 6.17508C20.5834 5.95841 20.5834 5.74175 20.4209 5.57925C20.2584 5.41675 20.0417 5.36258 19.825 5.47091ZM12.4042 23.9417L9.64171 16.6292C9.58754 16.4667 9.47921 16.3584 9.31671 16.3042L2.05837 13.5959L19.1209 6.93341L12.4042 23.9417Z"
        fill="#68E820"
      />
      <path
        d="M25.4584 0H22.2084C21.8834 0 21.6667 0.216667 21.6667 0.541667V3.79167C21.6667 4.11667 21.8834 4.33333 22.2084 4.33333H25.4584C25.7834 4.33333 26.0001 4.11667 26.0001 3.79167V0.541667C26.0001 0.216667 25.7834 0 25.4584 0ZM24.9167 3.25H22.7501V1.08333H24.9167V3.25Z"
        fill="#68E820"
      />
      <path d="M19.5001 1.625H18.4167V2.70833H19.5001V1.625Z" fill="#68E820" />
      <path d="M16.2501 1.625H15.1667V2.70833H16.2501V1.625Z" fill="#68E820" />
      <path d="M13.0001 1.625H11.9167V2.70833H13.0001V1.625Z" fill="#68E820" />
      <path d="M9.75008 1.625H8.66675V2.70833H9.75008V1.625Z" fill="#68E820" />
      <path d="M6.50008 1.625H5.41675V2.70833H6.50008V1.625Z" fill="#68E820" />
      <path d="M3.25008 1.625H2.16675V2.70833H3.25008V1.625Z" fill="#68E820" />
      <path d="M24.3751 6.5H23.2917V7.58333H24.3751V6.5Z" fill="#68E820" />
      <path d="M24.3751 9.75H23.2917V10.8333H24.3751V9.75Z" fill="#68E820" />
      <path d="M24.3751 13H23.2917V14.0833H24.3751V13Z" fill="#68E820" />
      <path d="M24.3751 16.25H23.2917V17.3333H24.3751V16.25Z" fill="#68E820" />
      <path d="M24.3751 19.5H23.2917V20.5833H24.3751V19.5Z" fill="#68E820" />
      <path d="M24.3751 22.75H23.2917V23.8333H24.3751V22.75Z" fill="#68E820" />
    </svg>
  );
}

export default Direction;
