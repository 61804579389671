import React from 'react'
import { CylinderHud, CylinderOutside } from './cylinder/CylinderHud'
import { StyleSheet, css } from 'aphrodite'
import { pageIdx } from '../../../constants'
import { CylinderOutsideHud } from './cylinder/CylinderOutsideHud'
import { PopupScreen } from './popup/PopupScreen'
import { Intro } from './cylinder/Intro'
import { generalStyles } from '../../../appStyles'
import { Logo } from '../../assets/svgs/Logo'
import { ShowcaseHUD } from './showcase/showcaseHUD'
import { useCustomFade } from '../../../stores'
import { About } from '../fullscreen/pages/About'
export const HUD = () => {
  const { status: fadeStatus } = useCustomFade()
  return (
    // <div className={css(styles.container)}>
    <div className={css(styles.container)}>
      {/* <Logo className={css(generalStyles.logo)} /> */}

      <CylinderHud />
      <CylinderOutsideHud />
      <About />
      <Intro />
      <ShowcaseHUD fadeStatus={fadeStatus} />
    </div>
    // {/* </div> */}
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: pageIdx.HUD,
    pointerEvents: 'none',
  },
})
