import { useEffect, useState } from 'react'
import axios from 'axios'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { PMREMGenerator, UnsignedByteType } from 'three'
import { useThree } from '@react-three/fiber'
import { baseUrl } from '../../../cms/cmsstore'
import { useControls } from 'leva'

export default function Skybox({ hdri, skyboxNames }) {
  const { gl, scene } = useThree()
  // const [skyboxNames,set] = useState(['whiteroom'])
  const { skybox } = useControls({
    skybox: { options: skyboxNames },
  })
  // useEffect(()=>{
  //   const fetchSkyboxNames = async () => {
  //     try{
  //       const res = await axios.get(baseUrl+'/skybox')
  //       set(res.data.map(x=>x.name))
  //       console.log(res.data.map(x=>x.name))
  //     }catch(err){
  //       console.log('skyboxes not found',err)
  //     }
  //   }
  //   fetchSkyboxNames()
  // },[])
  useEffect(() => {
    let pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath(baseUrl + '/skybox/')
      .load(skybox, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture
        scene.environment = envMap

        texture.dispose()
        pmremGenerator.dispose()
      })
  }, [gl, scene, hdri, skybox])

  return null
}
