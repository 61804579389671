import React from "react";
import { useModal } from "../cmsstore";

export const ItemsContainer = ({ data }) => {
  const modal = useModal();
  const handleClick = async (product) => {
    modal.setShow(true);
    modal.setData(product);
  };

  return (
    <table className="cms-table">
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Product Code</th>
          <th style={{ width: "60%" }}>Name</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {data.map((p, i) => (
          <tr key={i} onClick={() => handleClick(p)}>
            <td>{i + 1}</td>
            <td>{p.productCode}</td>
            <td>{p.name}</td>
            <td>{p.updatedAt}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
