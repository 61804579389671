export function preloadImage(url) {
    var img = new Image();
    img.src = url;
}

export function openURLNewTab(url) {
    window.open(url, '_blank');
}

export function approxeq(v1, v2, epsilon) {
    if (epsilon == null) {
        epsilon = 0.001;
    }
    return Math.abs(v1 - v2) < epsilon;
}
