import { useSpring } from "@react-spring/core";
import { getGPUTier } from "detect-gpu";
import React, { Suspense, useState, useRef } from "react";
import { useEffect } from "react";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import { useLocalLoader, usePage, useRoom } from "../../../stores";
import { InsideSuspense } from "../../loading/InsideSuspense";
import { Room } from "./Room";

const Load = () => {
  const localLoader = useLocalLoader();
  useEffect(() => {
    localLoader.setLoading(true);
    return () => localLoader.setLoading(false);
  }, []);
  return null;
};
export const Showcase = () => {
  const pageState = usePage();
  const room = useRoom();
  const loader = useLocalLoader();
  const [localRoom, setLocalRoom] = useState({ lastRoom: "", currentRoom: "" });
  const [roomInt, setRoomInt] = useState("");
  const [show, setShow] = useState(false);
  const gpu = useRef(3);
  // const {exit,enter} = useSpring({
  //     from:{exit:0,enter:0},
  //     exit:-30,enter:10,

  // })
  const { enter } = useSpring({
    from: { enter: 40 },
    enter: 0,
    reset: true,
  });
  useEffect(() => {}, [localRoom]);
  useEffect(() => {
    //console.log('roomurl',room.url)

    // console.log(loader.cache)
    // if(loader.progress===100 ){
    // room.url!==roomInt && setRoomInt(room.url)
    setTimeout(() => {
      if (room.url !== roomInt) {
        // if( !loader.cache.includes(room.url)) {
        //     loader.setProgress(0)
        // }
        setRoomInt("");
        setTimeout(() => {
          let isIphoneSafari = isIOS && isMobile && isSafari;
          setRoomInt(
            isIphoneSafari
              ? room.slrurl
              : isMobile || gpu.current === 1
              ? room.lrurl
              : room.url
          );
        }, [500]);
      }
    }, [300]);
    // }
    // setTimeout(setRoomInt(''),[200])
    // setTimeout(setRoomInt(room.url),[400])
  }, [room.url]);
  useEffect(() => {
    if (pageState.page === "showcase") {
      setShow(true);
    } else {
      setLocalRoom((state) => ({ ...state, lastRoom: "" }));
    }
  }, [pageState.page]);

  useEffect(() => {
    const getTier = async () => {
      const gpuTier = await getGPUTier();
      console.log("got gpu tier", gpuTier);
      gpu.current = gpuTier;
    };
    getTier();
  }, []);
  return (
    <>
      <Suspense fallback={<InsideSuspense />}>
        {roomInt !== false && roomInt !== "" && (
          <Room nospring url={roomInt} show={show} isSkinned={room.isSkinned} setShow={setShow} />
        )}
        {/* {
                    localRoom.currentRoom!=='' && <Room enter spring={enter} url={localRoom.currentRoom} />
                }
                {
                    (localRoom.lastRoom!=='' && loader.progress===100) && <Room exit spring={enter} url={localRoom.lastRoom} />
                } */}
      </Suspense>
    </>
  );
};
