import React from 'react'
import { colorThemes } from '../../../appStyles'

export const ArrowRight = ({ onClick, style, notransform }) => {
  return (
    <svg
      style={{ ...style, transform: notransform?'':'translate(-50%,-50%)', cursor:'pointer' }}
      onClick={onClick}
      width="100%"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.475098 12.7293V16.2709H21.7251L11.9855 26.0105L14.5001 28.5251L28.5251 14.5001L14.5001 0.475098L11.9855 2.98968L21.7251 12.7293H0.475098Z"
        fill="#FFFFFFA1"
      />
    </svg>
  )
}
export const ArrowLeft = ({ onClick, style, notransform }) => {
  return (
    <svg
      style={{ ...style, transform: notransform?'rotate(180deg)':'translate(-50%,-50%) rotate(180deg)', cursor:'pointer' }}
      onClick={onClick}
      width="100%"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.475098 12.7293V16.2709H21.7251L11.9855 26.0105L14.5001 28.5251L28.5251 14.5001L14.5001 0.475098L11.9855 2.98968L21.7251 12.7293H0.475098Z"
        fill="#FFFFFFA1"
      />
    </svg>
  )
}

export const ShowcaseArrowLeft = (props) => (
  <svg
    {...props}
    width="100%"
    viewBox="0 0 37 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.8917 6.72934L10.2011 31.7479L35.8917 56.9213C39.4187 60.2796 33.7091 65.9893 30.1821 62.631L0.967155 34.0993C0.37775 33.5012 0.0335587 32.7041 0.00232875 31.865C-0.0289012 31.0259 0.255092 30.2055 0.79841 29.5653L30.1821 1.02244C33.7091 -2.33587 39.4187 3.37104 35.8917 6.72934Z"
      fill="white"
    />
  </svg>
)

export const ShowcaseArrowRight = (props) => (
  <svg
    {...props}
    width="100%"
    viewBox="0 0 37 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.1083 56.924L26.7989 31.9054L1.1083 6.73197C-2.41873 3.37367 3.29091 -2.33601 6.81795 1.0223L36.0328 29.5541C36.6223 30.1521 36.9664 30.9492 36.9977 31.7883C37.0289 32.6274 36.7449 33.4478 36.2016 34.0881L6.81794 62.6309C3.29091 65.9892 -2.41874 60.2823 1.1083 56.924Z"
      fill="white"
    />
  </svg>
)

export const FeatureArrowRight = (props) => (
  <div {...props}>
    <svg style={{cursor:'pointer'}}  height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_779_138)">
      <path d="M8 6L12 10L8 14" stroke={'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="19.5" y="19.5" width="19" height="19" rx="3.5" transform="rotate(-180 19.5 19.5)" stroke="white"/>
      </g>
      <defs>
      <filter id="filter0_b_779_138" x="-15" y="-15" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feGaussianBlur in="BackgroundImage" stdDeviation="7.5"/>
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_779_138"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_779_138" result="shape"/>
      </filter>
      </defs>
    </svg>

  </div>

)

export const FeatureArrowLeft = (props) => (
  <div {...props}>
    <svg  style={{transform:'rotate(180deg)',cursor:'pointer'}}  height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_b_779_138)">
  <path d="M8 6L12 10L8 14" stroke={'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <rect x="19.5" y="19.5" width="19" height="19" rx="3.5" transform="rotate(-180 19.5 19.5)" stroke="white"/>
  </g>
  <defs>
  <filter id="filter0_b_779_138" x="-15" y="-15" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feGaussianBlur in="BackgroundImage" stdDeviation="7.5"/>
  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_779_138"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_779_138" result="shape"/>
  </filter>
  </defs>
  </svg>
  </div>
)