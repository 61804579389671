import React, { useState } from 'react'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'

export const CloseIcon = (props) => {
  return (
    // <div {...props}>
    <svg
      {...props}
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5322 6.22168L6.21852 17.5354"
        stroke="#888888"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M17.5322 17.5352L6.21852 6.22145"
        stroke="#888888"
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
    // </div>
  )
}

export const Disclaimer = (props) => {
  const style = useSpring({
    transform: !props.show ? `translateX(120%)` : `translateX(0%)`,
  })
  return (
    <a.div style={style} className={css(styles.disclaimer)}>
      <div className={css(styles.flex)}>
        <h1 className={css(styles.heading)}>DISCLAIMER</h1>
        <CloseIcon className={css(styles.close)} onClick={() => props.setDisclaimer(state => !state)} />
      </div>
      <p style={{ marginTop: '3.6vh' }} className={css(styles.desc)}>
      The artistic works contained in this website are in the form of a 3D environment with 3D assets,  and are meant only for representation purposes.
      </p>
      <p className={css(styles.desc)}>
      The products are  digitally enhanced for representational purpose. The shown views are indicative and artistic representations only and are not to be considered a part of any actual deliverables. The specifications and extent/ number /variety of the products displayed hereunder are indicative  and are  liable to change at the sole discretion of the company.
      </p>
      <p className={css(styles.desc)}>
      The artistic works, images, information on this website are provided in good faith and on an “AS IS” basis without any warranty, express, implied, or otherwise including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you. It is your responsibility to evaluate the accuracy, completeness of any information provided herein. Godrej & Boyce Mfg. Co. Ltd. may make any other changes to the site, the materials and the products, programs, services or prices (if any) described in the site at any time without notice.
      </p>
      <p className={css(styles.desc)}>
      All information contained herein is distributed with the understanding that the Company, authors, publishers and distributors are not rendering legal or other professional advice or opinions on specific facts or matters and accordingly assume no liability whatsoever in connection with its use. In no event shall Godrej & Boyce Mfg. Co. Ltd and its related, affiliated, and subsidiary companies  be held liable for any direct, indirect, special, incidental or consequential damages arising out of(i) the reliance or use of the information provided on this website or (ii) the access to this website or any weblink posted herein
      </p>
      <p className={css(styles.desc)}>
      While efforts are made to keep the website smooth and running, we strongly recommend that you perform a virus check at your end prior to accessing any weblink.
      </p>
      <p style={{ marginBottom: '7vh' }} className={css(styles.desc)}>
      These terms constitute a binding agreement between us and you regarding the reliance and use of this website.
      </p>
    </a.div>
  )
}
export const DisclaimerIcon = (props) => {
  // const [show, set] = useState(false)
  // const {
  //   currentContent: { name },
  // } = useAppState()
  // const { opacity } = useSpring({
  //   opacity: name === 'base' ? 0 : 1,
  // })
  // const style = useSpring({
  //   transform: !show ? 'translateX(140%)' : 'translateX(0%)',
  // })
  return (
    <>
      {/* <Disclaimer style={style} closeButton={() => set(false)} /> */}
      <a.div className={css(styles.disclaimerButton)} onClick={() => props.set(!props.show)}>
        {/* <a.svg
          onClick={() => set((state) => !state)}
          style={{
            lineHeight: '8.5vh',
            marginTop: '1.65vh',
            marginRight: '4vh',
            marginLeft: '1vh',
            opacity,
            pointerEvents: name === 'base' ? 'none' : 'auto',
            cursor: 'pointer',
          }}
          height="5vh"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.4057 4.06055C13.2231 4.06055 10.1709 5.32482 7.92048 7.57532C5.66998 9.82565 4.4057 12.878 4.4057 16.0605C4.4057 19.2431 5.66998 22.2954 7.92048 24.5458C10.1708 26.7963 13.2232 28.0605 16.4057 28.0605C19.5882 28.0605 22.6405 26.7963 24.8909 24.5458C27.1414 22.2954 28.4057 19.2431 28.4057 16.0605C28.4057 13.9542 27.8512 11.8848 26.7981 10.0606C25.7447 8.23635 24.23 6.72161 22.4057 5.66819C20.5814 4.61503 18.512 4.06055 16.4057 4.06055V4.06055ZM16.4057 26.5604C13.621 26.5604 10.9505 25.4542 8.98118 23.4851C7.01212 21.5158 5.90586 18.845 5.90586 16.0605C5.90586 13.2761 7.01208 10.6053 8.98118 8.63603C10.9504 6.66697 13.6213 5.56071 16.4057 5.56071C19.1901 5.56071 21.8609 6.66693 23.8302 8.63603C25.7993 10.6053 26.9055 13.2761 26.9055 16.0605C26.9055 17.9037 26.4204 19.7143 25.4988 21.3105C24.5772 22.9068 23.2518 24.2321 21.6556 25.1537C20.0593 26.0753 18.2488 26.5605 16.4056 26.5605L16.4057 26.5604ZM16.4057 19.0604C16.2068 19.0604 16.016 19.1395 15.8753 19.2801C15.7347 19.4207 15.6557 19.6116 15.6557 19.8104C15.6483 20.0212 15.7299 20.2254 15.8807 20.3729C16.0252 20.5005 16.2132 20.5676 16.4057 20.5604C16.5977 20.5616 16.783 20.4893 16.9232 20.3579C17.0777 20.2186 17.1627 20.0184 17.1557 19.8105C17.1569 19.6111 17.0785 19.4195 16.9382 19.2779C16.8014 19.1306 16.6066 19.0509 16.4057 19.0604L16.4057 19.0604ZM16.4057 17.5604C16.6046 17.5604 16.7954 17.4814 16.9361 17.3408C17.0767 17.2002 17.1557 17.0093 17.1557 16.8105V12.3105C17.1557 12.0425 17.0127 11.7949 16.7807 11.6609C16.5487 11.527 16.2627 11.527 16.0307 11.6609C15.7987 11.7949 15.6557 12.0425 15.6557 12.3105V16.8105C15.6557 17.0093 15.7347 17.2002 15.8753 17.3408C16.016 17.4814 16.2068 17.5604 16.4057 17.5604Z"
            fill="#F2F2F2"
          />
        </a.svg> */}
        DISCLAIMER
      </a.div>
    </>
  )
}

const styles = StyleSheet.create({
  disclaimerButton: {
    // position: 'absolute',
    color: '#ffffff',
    fontSize: '1.35vh',
    marginRight: '1%',
    fontFamily: 'Work Sans',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    // top: '3.20vh',
    // right: '3.20vh',
  },
  disclaimer: {
    width: '46vw',
    backgroundColor: 'rgba(0,0,0,0.95)',
    position: 'absolute',
    right: 0,
    // right: '0.6vw',
    top: '9vh',
    color: 'white',
    fontFamily: 'Work Sans',
    boxSizing: 'border-box',
    padding: '4.4vh',
    paddingBottom:'1vh',
    pointerEvents: 'auto',
    zIndex: 200,
    marginRight:'0.2vw',
  },
  flex: {
    display: 'flex',
    // marginTop: '3vh',
    justifyContent: 'space-between',
    height: '2.2vh',
  },
  heading: {
    fontSize: '1.8vh',
    fontWeight: 600,
    lineHeight: '2.6vh',
    margin: 0,
  },

  desc: {
    // marginTop: '4vh',
    fontSize: '1.8vh',
    lineHeight: '2.3vh',
    fontWeight: 200,
    textAlign: 'justify',
  },
  close: {
    cursor: 'pointer',
    transform: 'scale(1.4) translateY(-20%)',
  },
})
