import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { colorThemes, textStyles, textStylesV2 } from '../../../../../appStyles'
import { CircleLine } from '../../../../assets/svgs/CircleLine'
import { openURLNewTab } from '../../../../../helper'

export const ModelViewerContent = (props) => {
  return (
    <div className={css(styles.container)}>
      <h1 className={css(textStylesV2.HeadlineS)}>{props.name}</h1>
      <CircleLine className={css(styles.line)} />
      <p className={css(textStylesV2.LabelL)}>{props.category}</p>
      <p className={css(textStylesV2.BodyCard)}>{props.description}</p>
      <p className={css(textStylesV2.LabelL)}>Product Code</p>
      <p className={css(textStylesV2.BodyCard)}>{props.productCode}</p>
      <p className={css(textStylesV2.LabelL)}>Finish</p>
      <p className={css(textStylesV2.BodyCard)}>{props.finish}</p>
      <div className={css(styles.buttonContainer)}>
        <button
          onClick={() =>
            openURLNewTab(
              'https://www.godrej.com/godrej-locks-and-security-solutions/Locks'
            )
          }
          className={css(styles.cardButton)}
        >
          View Catalogue
        </button>
        <button
          onClick={() =>
            openURLNewTab('https://www.amazon.in/godrej-locks/s?k=godrej+locks')
          }
          className={css(styles.cardButton)}
        >
          Shop
        </button>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  headingColor: {
    color: '#777474',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '5% 10% 5% 2%',
    textAlign: 'right',
    fontFamily: 'Roboto',
    opacity: 0.96,
    color: colorThemes.text[0],
  },
  heading: {
    fontFamily: 'Helvetica',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: '401',
    lineHeight: '39px',
    letterSpacing: '0em',
  },
  small: {
    weight: '400px',
    fontSize: '15px',
    lineHeight: '17.58px',
  },
  smaller: {
    marginTop: '6%',
    weight: '400px',
    opacity: 0.8,
    fontSize: '12px',
    lineHeight: '14.58px',
    fontWeight: 500,
    fontFamily: 'Arial',
    marginRight: '0.5%',
  },
  robotoBlack: {
    fontFamily: 'Arial',
    margin: '1%',
    fontWeight: 700,
    color: colorThemes.text[0],
    marginTop: '4%',
  },
  color: {
    fontFamily: 'Arial',
    fontWeight: 400,
    color: colorThemes.text[0],
    margin: 0,
    marginRight: '0.5%',
    opacity: 0.8,
  },
  line: {
    height: '1%',
    marginTop: '-1%',
    marginLeft: '15%',
    overflow: 'visible',
    // left:'-50%',
    width: '100%',
    position: 'relative',
    top: 0,
  },

  buttonContainer: {
    textAlign: 'center',
    paddingTop: '1vh',
  },
  cardButton: {
    border: 'none',
    outline: 'none',
    padding: '1vw',
    width: '12vw',
    backgroundColor: '#bfbfbf',
    color: 'white',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: '0.9vw',
    borderRadius: 15,
    margin: 5,
    ':active': {
      backgroundColor: '#515151',
    },
  },
  // roboto:{
  //     fontFamily:'Roboto'
  // }
})
