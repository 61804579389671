import { Html } from '@react-three/drei'
import React, { useEffect } from 'react'
import { animated } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { StyleSheet, css } from 'aphrodite'
import { useCustomFade } from '../../stores'
import { colorThemes } from '../../appStyles'

export default function CustomFade() {
  const { status } = useCustomFade()

  const [styles, api] = useSpring(() => ({
    opacity: 0,
  }))

  useEffect(() => {
    if (status) {
      api.start({ opacity: 1 })
    } else {
      api.start({ opacity: 0 })
    }
  }, [status, api])

  return (
    <Html fullscreen style={{ pointerEvents: 'none', opacity:0.2 }}>
      <animated.div style={styles} className={css(localStyle.background)}>
        <div className={css(localStyle.loadingContainer)}>
          <div className={css(localStyle.loadingTitle)}>Loading</div>
          <div className={css(localStyle.loadingSubtitle)}>
            This will only take a few moments
          </div>
        </div>
        {/* css loader thing  */}
        {/* <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      </animated.div>
    </Html>
  )
}

const localStyle = StyleSheet.create({
  background: {
    height: '200vh',
    width: '200vw',
    backgroundColor: 'black',
  },
  loadingContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: '15vh',
  },
  loadingTitle: {
    paddingTop: '8vh',
    fontSize: 36,
    opacity: 0.9,
    color: colorThemes.text[0],
  },
  loadingSubtitle: {
    paddingTop: '2vh',
    fontSize: 18,
    opacity: 0.7,
    color: colorThemes.text[0],
  },
})
