import { config, useSpring } from "@react-spring/core";
import { useGLTF, useAnimations } from "@react-three/drei";
import React, { useEffect, useRef, useState } from "react";
import KitchenModel from "../../assets/Kitchen_Environment";
import {
  useLocalLoader,
  usePage,
  useRoom,
  usePopup,
  useCylinder,
  useNewLoadingScreen,
} from "../../../stores";
import { HotspotManager } from "./hotspots/HotspotManager";
import { animated as a } from "@react-spring/three";
import * as THREE from "three";
import { useControls } from "leva";
import { RoomAnimations } from "./RoomAnimations";
import { SpawnPointManager } from "./spawnpoints/SpawnPointManager";

function playAnimationForward(anim) {
  if (!anim) return;
  anim.loop = THREE.LoopOnce;
  anim.timeScale = 1;
  anim.clampWhenFinished = true;
  anim.paused = false;
  anim.play();
}

function playAnimationBackward(anim) {
  if (!anim) return;
  anim.loop = THREE.LoopOnce;
  anim.clampWhenFinished = true;
  anim.timeScale = -1;
  anim.paused = false;
  anim.play();
}

export const Room = ({ setShow, show, isSkinned, url, ...props }) => {
  const popup = usePopup();
  const room = useRoom();
  const mesh = useRef();
  const loader = useLocalLoader();
  const pageState = usePage();
  const model = useGLTF(
    url,
    "https://www.gstatic.com/draco/versioned/decoders/1.5.0/"
  );
  const cylinder = useCylinder();
  const { toggle } = useControls({ toggle: { value: false } });
  const modelAnimation = useAnimations(model.animations, model.scene);
  console.log("This is MODEL ANIMATION",modelAnimation)
  const newLoader = useNewLoadingScreen();
  useEffect(() => {
    // console.log('ANIMATION MIXER', modelAnimation.mixer)
    setTimeout(() => newLoader.setIsLoading(false), [200]);

    if (url === "/environment/Fin_AmandoDisplay1.glb") {
      // model.position.setY(model.scene.position.y)
    }
    // let cache = new Cache()
    // console.log('cache', window.Cache.keys())
    !(url in loader.cache) && loader.appendToCache(url);
  }, []);
  const spring = useSpring({
    from: { y: props.enter ? 20 : 0 },
    y: props.enter ? 0 : 20,
  });
  return (
    <>
      <a.group position={cylinder.room == 1 ? [0, 0.5, 0] : [0, 0, 0]}>
        {
          <a.primitive
          object={model.scene}
        />
        }
      </a.group>
      <HotspotManager
        hotspots={room.data.hotspots?.map((x, i) => ({ ...x, id: i }))}
      />
      <SpawnPointManager
        spawnpoints={room.data.spawnpoints?.map((x, i) => ({ ...x, id: i }))}
      />
      <RoomAnimations room={room.data.id} animations={modelAnimation.actions} mixer={modelAnimation.mixer}  />
    </>
  );
};
