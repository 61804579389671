import { StyleSheet } from "aphrodite";

export const commonStyles = StyleSheet.create({
  paragraph: {
    fontFamily: "Helvetica",
    color: "#093145",
    fontSize: 18,
  },
  heading: {
    fontFamily: "Helvetica",

    color: "#093145",
    fontSize: 27,
  },
  label: {
    fontSize: "0.7em",
    marginRight: "0.5em",
  },
  labelBold: {
    fontWeight: "bold",
    fontSize: "0.8em",
  },
  required: {
    color: "red",
    fontSize: "1.2em",
  },
  input: {
    width: "100%",
    fontSize: "1em",
    outline: "none",
    border: "1.2px solid #b7b7b7",
    borderRadius: "4px",
    margin: "0.1em 0.2em 0.4em 0.2em",
    padding: "4px 6px",
  },
  fileInput: {
    marginBottom: "1em",
  },
});
