import React, { useEffect, useState, useRef } from 'react'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'

export const ScrollStatus = (props) => {
  const [rot, set] = useState(props.room)
  const lastRot = useRef(props.room)
  useEffect(() => {
    // //console.log('rotation',rot, lastRot.current-props.room,props.room)
    lastRot.current =
      lastRot.current - props.room > 2
        ? -1
        : lastRot.current - props.room < -2
        ? 6
        : lastRot.current
    set(rot + (lastRot.current - props.room))
    lastRot.current = props.room
  }, [props.room])
  const spring = useSpring({ transform: ` rotate(${rot * -60})` })
  return (
    <a.svg
      {...spring}
      {...props}
      width="113"
      height="114"
      viewBox="0 0 113 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <circle
          cx="56.2877"
          cy="52.8538"
          r="38.5"
          transform="rotate(-25.8961 56.2877 52.8538)"
          stroke="#CFCFCF"
        />
        <circle
          cx="77.4305"
          cy="99.8377"
          r="4"
          transform="rotate(-25.8961 77.4305 99.8377)"
          fill="#C4C4C4"
          stroke="#CFCFCF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.170898"
          y="0.736816"
          width="112.234"
          height="113.114"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </a.svg>
  )
}
