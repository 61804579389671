import { Html } from '@react-three/drei';
import { useControls } from 'leva';
import React, { useEffect, useState } from 'react';
import {
    useCameraStore,
    useCylinder,
    usePage,
    usePopup,
    useProducts,
    useRoom,
    useWorkaround,
} from '../../../../stores';
import { rooms } from '../../../../constants';
import { animated as a } from '@react-spring/web';
import { config, useSpring } from '@react-spring/core';
import { css, StyleSheet } from 'aphrodite';
import { textStylesV2 } from '../../../../appStyles';
import { isMobile } from 'react-device-detect';
import { sendEvent } from '../../../../google-analytics';
import { useThree } from '@react-three/fiber';
import { approxeq } from '../../../../helper';

export function findProductCodeInArray(code, array) {
    for (var i in array) {
        if (array[i].productCode == code) return array[i];
    }
    return null;
}

export const Hotspot = (props) => {
    const { camera } = useThree();
    const workaround = useWorkaround();
    const pageState = usePage();
    const cameraState = useCameraStore();
    const { products } = useProducts();
    const cylinder = useCylinder();
    const [productName, setProductName] = useState(null);
    const [hover, setHover] = useState(false);

    const popup = usePopup();
    const {
        fov,
        hotspot_position: { x, y, z },
        print_hotspot,
    } = useControls({
        fov: { value: 45, step: 1 },
        hotspot_position: { value: { x: 0, y: 0, z: 0 }, step: 0.025 },
        print_hotspot: { value: false },
    });
    function handleClick() {
        // workaround.setUrl(`/showcase`)
        //console.log('asdasd',props.id)
        sendEvent(`hotspot ${props.id} Clicked`, 'Hotspot Manager', 'Hotspot');

        workaround.setType(0); //Hotspot type  =  0
        workaround.setPopup(-1);
        workaround.setPopup(props.id);
    }
    useEffect(() => {
        print_hotspot && console.log(`position:[${x},${y},${z}]`);
    }, [print_hotspot]);

    useEffect(() => {
        if (productName == null || productName == '') {
            let pd = findProductCodeInArray(props.productCode, products);
            if (pd != null) setProductName(pd.name);
            else setProductName('');
        }
    }, [props]);
    const handleLog = () => {
        //console.log([x,y,z].map(Number))
    };

    // Popup.data gives us the info of whatever point we clicked on (hotspot or spawnpoint);
    //props.spawnpoints = value of spawnpoint in hotspot;
    //the actual value of spawnpoint = popup.Id
    function hotspotVisibility(popupData, props, pageState, cameraState) {
        let visibility = 0;

        const room = cylinder.room;
        const currentRoom = rooms[room];

        // Special case for room with spawn points ->

        // If SpawnPoint is selected && CameraPosition is Same as Spawn Point Target Position -> Dont' show any hotspot
        // Else There is a SpawnPoint -> Show the hotspot respect to the spawnpoints mapped

        // Else keep the same conditions for hotspots
        if (currentRoom.spawnpoints.length > 0) {
            const currentSpawnPointData = currentRoom?.spawnpoints?.[popupData?.popupId];

            const spawnPointCameraData = {
                x: currentSpawnPointData?.view?.position?.[0],
                y: currentSpawnPointData?.view?.position?.[1],
                z: currentSpawnPointData?.view?.position?.[2],
            };

            const isCameraNearlyEqual =
                approxeq(spawnPointCameraData?.x, camera?.position?.x) &&
                approxeq(spawnPointCameraData?.y, camera?.position?.y) &&
                approxeq(spawnPointCameraData?.y, camera?.position?.y);

            // console.log('Is Camera Nearly Equal', isCameraNearlyEqual);
            if (
                !isNaN(props.spawnpoint) &&
                props.spawnpoint === popupData.popupId &&
                cameraState.tweening === false &&
                isCameraNearlyEqual
            ) {
                // console.log('Went to condition 2');
                visibility = 1;
            } else {
                visibility = 0;
            }
        } else {
            if (pageState === 'showcase' && popupData.popupId == null && cameraState.tweening === false) {
                // console.log('Went to condition 1');
                visibility = 1;
            } else {
                visibility = 0;
            }
        }

        // if (pageState === 'showcase' && popupData.popupId == null && cameraState === false) {
        //     console.log('Went to condition 1');
        //     visibility = 1;
        // } else {
        //     visibility = 0;
        // }

        // if (pageState === 'showcase' && popupData.popupId == null && isNaN(props.spawnpoint) && cameraState === false) {
        //     console.log('Went to condition 1');
        //     visibility = 1;
        // } else if (!isNaN(props.spawnpoint) && props.spawnpoint === popupData.popupId && cameraState === false) {
        //     console.log('Went to condition 2');
        //     visibility = 1;
        // } else {
        //     visibility = 0;
        // }
        // console.log('Visibility:', visibility);
        return visibility;
    }

    const { opacity, opacity2 } = useSpring({
        from: { opacity: 0, opacity2: 0 },
        opacity: hotspotVisibility(popup.data, props, pageState.page, cameraState),
        opacity2: hover ? 1 : 0,
        delay: 0,
    });

    const pulseAnimation = useSpring({
        from: {
            transform: 'scale(1)',
            opacity: 0.9,
        },
        to: [
            { transform: 'scale(0.7)', opacity: 0.7 },
            { transform: 'scale(1)', opacity: 0.9 },
        ],
        loop: true,
        config: config.molasses,
    });

    function handleHover(stat) {
        setHover(stat);
    }
    return (
        <>
            <group position={[0, 0, 0]}>
                <group position={props.position}>
                    <Html
                        zIndex={1}
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            height: '5vh',
                            pointerEvents: 'none',
                        }}
                        zIndexRange={[3, 1]}
                    >
                        <a.div
                            style={{
                                opacity,
                            }}
                        >
                            <a.div
                                className={css(textStylesV2.HotspotR, textStylesV2.BasicColor)}
                                style={{
                                    opacity: opacity2,
                                    position: 'absolute',
                                    padding: '1.7vh',
                                    marginLeft: props.hoverLeft ? '2vw' : '2vw',
                                    transform: props.hoverLeft ? 'translateX(-112%)' : 'translateX(-0%)',
                                    marginTop: '1vw',
                                    width: 'max-content',
                                    backgroundColor: '#4F4F4FEA',
                                    // backgroundColor: 'blue',
                                    pointerEvents: 'none',
                                }}
                            >
                                {productName}
                            </a.div>
                            <img
                                onPointerDown={handleClick}
                                onPointerOver={() => handleHover(true)}
                                onPointerOut={() => handleHover(false)}
                                src="./misc/hotspot.png"
                                alt={productName}
                                className={css(!hover ? style.pulseAnimation : '')}
                                style={{
                                    marginTop: '6.5vh',
                                    height: isMobile ? '5vh' : '3.5vh',
                                    cursor: 'pointer',
                                    pointerEvents: 'auto',
                                }}
                            />
                        </a.div>
                    </Html>
                </group>
            </group>
            {/* <group  position={[x,y,z]}>
                <Html style={{position:'absolute',zIndex:1}} zIndexRange={[3,1]}>
                    <div onPointerDown={handleLog} style={{backgroundColor:'black',width:'15px',height:'15px',cursor:'pointer',borderRadius:'100%'}}></div>                    
                </Html>
            </group> */}
        </>
    );
};

const opacityKeyframes = {
    from: {
        opacity: 1,
        transform: 'scale(0.9)',
    },

    to: { opacity: 0.5, transform: 'scale(0.8)' },
};

const style = StyleSheet.create({
    pulseAnimation: {
        animationName: [opacityKeyframes],
        animationDuration: '1s, 1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
});

export const HotspotManager = (props) => {
    console.log(`Props being passed down `, props);

    return props.hotspots?.map((hotspot, i) => {
        return <>{<Hotspot key={i} {...hotspot} />}</>;
    });
};
