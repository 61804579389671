import { Html } from '@react-three/drei'
import React from 'react'

export const TrailingCursor = () => {
    return ( null && 
    <Html fullscreen style={{
    }}>
        
        <div style={{
            // top:'50%',
            // left:'50%',
            // transform
            // position:'absolute',
            width:'40px',
            height:'40px',
            border:'3px solid whitesmoke'
        }}>
            
        </div>

    </Html>
    )
}
