import React, { Suspense, useState, useEffect, useRef, useMemo } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Canvas, useThree } from '@react-three/fiber'
import { Html, OrbitControls, useGLTF, useProgress } from '@react-three/drei'
import { GlbParser, Parse } from '../../../../Misc/GlbParser'
import Skybox from '../../../../core/skybox/Skybox'
import { SrcAlphaFactor } from 'three'
import StaticSkybox from '../../../../core/skybox/StaticSkybox'
import { textStylesV2 } from '../../../../../appStyles'
import ProductSkybox from '../../../../core/skybox/ProductSkybox'
import { pulse, rotateIn } from 'react-animations'
import { isDesktop } from 'react-device-detect'
import { useSpring } from '@react-spring/web'
import { animated as a } from '@react-spring/web'

const Model = (props) => {
  const object = useGLTF(props.url)
  const { camera } = useThree()
  const controls = useRef()
  const { invalidate } = useThree()
  const [geometry, setGeometry] = useState()
  // const [primitiveProps, setPrimitiveProps] = useState()
  // const primitiveProps = null

  const primitiveProps = {
    object: geometry,
  }

  useEffect(() => {
    if (
      // props.shouldShow &&
      props.view &&
      props.view.position &&
      props.view.lookAt
    ) {
      controls.current.target.set(...Object.values(props.view.lookAt))
      camera.position.set(...Object.values(props.view.position))
      camera.zoom = props.view.zoom
    }
  }, [])

  const copiedScene = useMemo(() => object.scene.clone(), [object])

  return (
    // <GlbParser object={object} />
    <>
      <OrbitControls
        ref={controls}
        enablePan={false}
        // maxDistance={6}
        minDistance={1}
        rotateSpeed={0.3}
        // minAzimuthAngle={Math.PI / (props.view.maxLeft || -2)}
        // maxAzimuthAngle={Math.PI / (props.view.maxRight || 2)}
      />
      {/* <primitive scale={28} object={object.scene} /> */}
      <group>
        <primitive object={copiedScene} scale={28} />
      </group>
      <Skybox />
    </>
  )
}
const Loading = (props) => {
  const { progress } = useProgress()
  const vid = useRef()
  useEffect(() => {
    if (vid.current) {
      vid.current.muted = true
      vid.current.play()
    }
    return () => props.showTip()
  }, [])

  useEffect(() => {
    //console.log('model prog',progress)
  }, [progress])
  return (
    <Html fullscreen style={{}}>
      <>
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className={css(textStylesV2.BodyR, textStylesV2.BasicColor)}
          style={{
            position: 'absolute',
            bottom: '6vh',
            left: 0,
            marginLeft: '10%',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '80%',
              textAlign: 'center',
            }}
          >
            Loading 3D Model
          </div>
          <div
            style={{
              width: '80%',
              fontSize: '2vh',
              textAlign: 'center',
            }}
          >
            This will take a few moments
          </div>
        </div>
      </>
    </Html>
  )
}
export const ModelViewer = (props) => {
  const [showText, set] = useState(false)
  const { opacity } = useSpring({
    opacity: showText ? 1 : 0,
  })
  // useEffect(() => {
  //   console.log('view: ' + props.view + 'url: ' + props.url)
  // })

  return (
    <div onPointerDown={() => set(false)} {...props}>
      <Canvas>
        <Suspense fallback={<Loading showTip={() => set(true)} />}>
          {props.shouldShow && (
            <>
              <Model
                view={props.view}
                url={props.url}
                shouldShow={props.shouldShow}
              />
              <ProductSkybox />
            </>
          )}
        </Suspense>
      </Canvas>
      <a.p style={{ opacity }} className={css(styles.p)}>
        {isDesktop ? 'Click and drag to rotate' : 'Swipe to rotate'}
      </a.p>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  p: {
    position: 'absolute',
    bottom: '1vh',
    left: '50%',
    transform: 'translateX(-50%)',
    animationName: pulse,
    animationDuration: 1000,
    animationIterationCount: 'infinite',
    fontFamily: 'Roboto',
    color: 'white',
    fontSize: '2.4vh',
  },
})
