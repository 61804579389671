import { useThree } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { PMREMGenerator, UnsignedByteType } from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { preloadlist, SkyboxMain, SkyboxRooms } from '../../../constants'
import { useCustomLoader, useCylinder, useNewLoadingScreen, usePage } from '../../../stores'

export default function StaticSkybox() {
  const preList = useRef(preloadlist)
  const [current, setCurrent] = useState(0)
  const [preloaded, setPreloaded] = useState(false)
  function onPreLoadDone(index) {
    console.log(index)
    index++
    if (index < preList.current.length) setCurrent(index)
    else {
      setPreloaded(true)
    }
  }

  return (
    <>
      {!preloaded && (
        <>
          {current != null && (
            <PreLoadSkybox
              name={preList.current[current]}
              isDone={() => onPreLoadDone(current)}
            />
          )}
        </>
      )}
      {preloaded && <SkyboxUser />}
    </>
  )
}

function SkyboxUser() {
  const { gl, scene } = useThree()
  const { reportLoaderDone } = useCustomLoader()
  const {setIsSkyboxLoading} = useNewLoadingScreen()
  const { page } = usePage()
  const { room } = useCylinder()
  const [name, setName] = useState('sample.hdr')
  // const dontchangesb = ['about', 'categories','mv','catalog',]
  useEffect(() => {
    // if(page!='about')
    if (page === 'cylinder' || page === 'cylinderoutside' || page==='/') setName(SkyboxMain)
    else if (page == 'showcase') {
      setName(SkyboxRooms[room])
    }
  }, [page, room])

  useEffect(() => {
    setIsSkyboxLoading(true)
    let pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath('skybox/')
      .load(name, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture
        scene.environment = envMap
        setTimeout(() => setIsSkyboxLoading(false),[600])
        reportLoaderDone('skybox')
        texture.dispose()
        pmremGenerator.dispose()
      })

    return function cleanup() {
      pmremGenerator.dispose()
    }
  }, [gl, scene, name])

  useEffect(() => {}, [])
  return null
}

function PreLoadSkybox({ name, isDone }) {
  const { gl } = useThree()

  useEffect(() => {
    let pmremGenerator = new PMREMGenerator(gl)
    pmremGenerator.compileEquirectangularShader()
    new RGBELoader()
      .setDataType(UnsignedByteType)
      .setPath('skybox/')
      .load(name, function (texture) {
        isDone()
        texture.dispose()
        pmremGenerator.dispose()
      })

    return function cleanup() {
      pmremGenerator.dispose()
    }
  }, [gl, isDone, name])

  return null
}
