import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { usePage } from '../../../../../stores'
import { colorThemes } from '../../../../../appStyles'
import { thumbnailUrl } from '../../../../../cms/cmsstore'

export const Card = (props) =>
  props.img ? (
    <div
      style={{ pointerEvents: 'auto' }}
      className={css(styles.cardContainer)}
    >
      <div className={props.small ? css(styles.smallCard) : css(styles.card)}>
        <div
          // onTouchEnd={props.onClick} onMouseUp={props.onClick}
          onClick={props.onClick}
          style={{
            // width: '100%',
            height: '80%',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
        >
          {props.small || props.products ? (
            <img
              alt="product"
              style={{
                borderRadius: '5px',
                pointerEvents: 'none',
                userSelect: 'none',
                backgroundColor: 'white',
              }}
              className={css(styles.img)}
              maxWidth="100%"
              height="100%"
              src={props.img}
            />
          ) : (
            <img
              style={{
                borderRadius: '5px',
                pointerEvents: 'none',
                userSelect: 'none',
                minWidth:'300px'
              }}
              maxWidth="100%"
              height="100%"
              className={css(styles.img)}
              src={props.img}
              alt=""
            />
          )}
        </div>
        <p
          style={{ pointerEvents: 'auto' }}
          className={props.small ? css(styles.smallText) : css(styles.text)}
        >
          {props.name}
        </p>
      </div>
    </div>
  ) : null

const styles = StyleSheet.create({
  card: {
    minWidth: '22vw',
    height: '90%',
    backgroundColor: colorThemes.card[0],
    textAlign: 'center',
    padding: '4% 4% 0% 4%',
    zIndex: 55,
    marginLeft: '5.5vw',
    boxSizing: 'border-box',
    borderRadius: '7px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    // transition: '0.3s',
    touchAction: 'none',
  },
  smallCard: {
    height: '90%',
    width: '8vw',
    marginRight: '0.5vw',
    borderRadius: '5px',
    // backgroundColor:'red',
    touchAction: 'none',
  },
  text: {
    textAlign: 'left',
    pointerEvents: 'none',
  },
  smallText: {
    textAlign: 'center',
    margin: 0,
    pointerEvents: 'none',
  },
  img: {
    maxWidth: '24vw',
    maxHeight: '60vh',
    // margin:'10%'
    touchAction: 'none',
    pointerEvents: 'none',
    userSelect: 'none',
    borderRadius: '11px',
  },
})
