import React, { useEffect, useRef, useState } from "react";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
// import devContent from './../dev.json'
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";

export default function LoadingBar({ amount }) {
  const [intAmount, setIntAmount] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const time = useRef()

  useEffect(() => {
    if (intAmount > amount) {
      if (shouldUpdate || amount === 0) {
        amount===0?setTimeout(() => setIntAmount(0),[1000]):setIntAmount(amount);
        setShouldUpdate(false);
        time.current = setTimeout(() => setShouldUpdate(true), [6000]);
      }
    } else {
      setIntAmount(amount);
      setShouldUpdate(false);
      time.current = setTimeout(() => setShouldUpdate(true), [6000]);
    }
    return () => clearTimeout(time.current)
  }, [amount]);
  useEffect(() => {
    console.log(shouldUpdate ,intAmount)
  },[intAmount, shouldUpdate])
  const bar = useSpring({
    // width: amount !== 50 ? amount + "%" : "0%",
    width: intAmount + '%' ,
  });
  return (
    <div className={css(styles.barContainer)}>
      <animated.div style={bar} className={css(styles.loadingBar)} />
    </div>
  );
}

LoadingBar.propTypes = {
  amount: PropTypes.number,
};

const styles = StyleSheet.create({
  loadingBar: {
    backgroundColor: "#C4C4C4",
    height: "100%",
  },
  barContainer: {
    // backgroundColor: "white",
    // border: "black 1px solid",
    bottom: "0",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    position: "absolute",
    height: "2.5%",
  },
});
